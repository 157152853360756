import {
  Heading,
  Link,
  ListItem,
  OrderedList,
  Stack,
  Text
} from "@chakra-ui/react";
import React from "react";

interface PromotionalSmsPolicyComponentProps {
  modal?: boolean;
}

export const PromotionalSmsPolicyComponent: React.FC<
  PromotionalSmsPolicyComponentProps
> = (props: PromotionalSmsPolicyComponentProps) => {
  return (
    <Stack id="promotional-sms-policy" spacing="12">
      {!props.modal ? (
        <Heading fontSize={{ base: "2xl", xl: "3xl" }}>
          Promotional SMS Policy
        </Heading>
      ) : (
        <></>
      )}
      <Stack>
        <Heading fontSize={{ base: "lg", xl: "xl" }}>BTRC Regulations</Heading>
        <OrderedList>
          <ListItem fontSize={{ base: "md" }}>
            Dear User/Client, Greetings! As per regulations, all kinds of
            promotional/greetings SMS have to be in Bangla (Unicode) for both
            Campaign and API. Except only machine-generated SMS/notification
            (example: ATM card OTP etc.), all other SMS content must be in
            Bangla and all are requested to strictly follow this regulation.
            Otherwise, the SMS account will be blocked and the User/Client will
            bear the responsibility or any damage caused due to violation of
            this regulation. Thanks.
            <br />
            <br />
            [নিয়ম অনুযায়ী, টারগেটিং / এপিআই / নাম্বারলিস্ট এর সকল ধরণের
            প্রোমোশনাল / গ্রিটিংস এসএমএস অবশ্যই বাংলায় (ইউনিকোড) হতে হবে।
            শুধুমাত্র মেশিন জেনারেটেড এসএমএস / নোটিফিকেশন (উদাহরণঃ এটিএম কার্ড
            OTP) ,ছাড়া সব ধরণের এসএমএস অবশ্যই বাংলা ( ইউনিকোড) ব্যবহার করতে
            আহবান এবং সতর্ক করা হচ্ছে। অন্যথায় একাউন্ট স্থগিত হবে এবং
            ইউজার/গ্রাহক/ক্লায়েন্ট এর দায়ভার বহন করবেন। ধন্যবাদ।]
          </ListItem>
          <br />
          <ListItem fontSize={{ base: "md" }}>
            Content must have to vet from BTRC before broadcast and Share the
            vetting content to MNO's.
          </ListItem>
          <br />
        </OrderedList>
        <Text fontSize={{ base: "md" }}>
          Please take immediate action and comply with BTRC guideline.{" "}
        </Text>{" "}
      </Stack>

      <Stack>
        <Heading fontSize={{ base: "lg", xl: "xl" }}>PDF</Heading>
        <Text fontSize={{ base: "md" }} colorScheme="blue">
          <Link
            isExternal
            href="/documents/btrc-promotional-sms-regulation.pdf"
            color="red"
          >
            BTRC Promotion SMS Regulation
          </Link>
        </Text>
      </Stack>
      <Stack>
        <Heading fontSize={{ base: "lg", xl: "xl" }}>Compliance</Heading>
        <Text fontSize={{ base: "md" }} fontWeight="bold">
          By pressing on "I Agree", you agree to following these regulations and
          bear any responsibility in case of failure to oblige.
        </Text>
      </Stack>
    </Stack>
  );
};
