import {
  Heading,
  ListItem,
  OrderedList,
  Stack,
  Text,
  UnorderedList
} from "@chakra-ui/react";
import React from "react";

interface TermsOfServiceComponentProps {
  modal?: boolean;
}

export const TermsOfServiceComponent: React.FC<TermsOfServiceComponentProps> = (
  props: TermsOfServiceComponentProps
) => {
  return (
    <Stack id="terms-of-service" spacing="12">
      {!props.modal ? (
        <Heading fontSize={{ base: "2xl", xl: "3xl" }}>
          Terms of Service
        </Heading>
      ) : (
        <></>
      )}
      <Stack>
        <Heading fontSize={{ base: "lg", xl: "xl" }}>
          Charges & Payments
        </Heading>
        <Text fontSize={{ base: "md", xl: "lg" }}>
          In the event the usage of these services is charged to you by a Third
          Party which is officially licensed to do so by{" "}
          <Text fontSize={{ base: "md", xl: "lg" }} as="span" fontWeight="bold">
            Bindulogic Limited
          </Text>
          , the terms agreed between you, and such Third Party shall apply.
          These terms and conditions are a binding legal agreement between the
          customer (you) and Bindulogic Limited contain provisions that
          disclaim, limit, and exclude our liability to you and that indemnify
          us against claims and damages that it may suffer as a result of your
          conduct. By ticking the “I accept these terms and conditions” checkbox
          and submitting the request or otherwise registering for the services,
          you agree that:
        </Text>{" "}
        <OrderedList>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            You agree to pay all charges due to us regarding making the Service
            available to you and your access to and use the service (“Customer
            Charges”) by the pre-payment method and following the terms as set
            hereof.
          </ListItem>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            Customers are required to purchase credits which shall reduce for
            each message sent. The number of credits used per individual message
            depends on the message type and length.
          </ListItem>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            Payment made by any digital method like a mobile bank, debit/credit
            card, and digital wallet may charge an additional amount as a
            processing fee.
          </ListItem>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            No payment processing charges will be mentioned on our invoice. Only
            the actual price of the product/service will be shown on our
            invoice.
          </ListItem>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            You may receive an invoice/SMS/email from your chosen payment
            gateway, including a processing fee.
          </ListItem>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            The transaction processing fee will be automatically deducted from
            your chosen payment method during the digital/online payment.
          </ListItem>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            There is no additional charge or processing fee for direct bank
            deposit, bank transfer, and cash deposit at our office.
          </ListItem>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            We do not offer any option to transfer account to account credit.
          </ListItem>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            If the products/services are taxable, the tax will be added to the
            principal amount per government rules.
          </ListItem>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            Billing for the applicable charges shall be in BDT (Bangladeshi
            Taka) and as specified on the service's specific service terms and
            conditions.
          </ListItem>
        </OrderedList>
      </Stack>

      <Stack>
        <Heading fontSize={{ base: "lg", xl: "xl" }}>
          Account Activation
        </Heading>
        <OrderedList>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            Subject to user agreement, the person signing up for the Service
            will be the contracting party (“Account Owner”) for our Terms of
            Service and will be the person who is authorized to use any
            corresponding account we may provide to the Account Owner in
            connection with the Service.
          </ListItem>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            If you are signing up for the Service on behalf of your employer,
            your employer shall be the Account Owner. If you are signing up for
            the Service on behalf of your employer, you represent and warrant
            that you have the authority to bind your employer to our Terms of
            Service.
          </ListItem>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            Upon completing sign-up for the Service, We will create a BinduSMS
            account on your behalf, using your provided information. Depending
            on your documents, the BinduSMS authority can accept or reject your
            application.
          </ListItem>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            The customer may have to sign the “Terms and Conditions” form and
            must submit it along with the required documents.
          </ListItem>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            The document or photo ID provided must be at least 6 months
            validity; if there is an expiration date.
          </ListItem>
        </OrderedList>
      </Stack>

      <Stack>
        <Heading fontSize={{ base: "lg", xl: "xl" }}>Definition</Heading>
        <UnorderedList>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            <Text
              fontSize={{ base: "md", xl: "lg" }}
              as="span"
              fontWeight="bold"
            >
              Application to Person (A2P) SMS
            </Text>
            : Application to person, mobile / system generated SMS sending
            process. A2P SMS is not transmitted by any mobile device (handset,
            tab, SIM box, etc.) and SIM card. A2P SMS is in the form of text.
            For transaction and marketing, One-way SMS, OTP, PIN code, etc., are
            included in A2P SMS. A2P SMS reaches the customer by alpha
            sender/masking, numeric sender-id / non-masking (virtual mobile
            number), shortcode, etc.
          </ListItem>{" "}
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            <Text
              fontSize={{ base: "md", xl: "lg" }}
              as="span"
              fontWeight="bold"
            >
              SMS Aggregator
            </Text>
            : It is an SMS service provider (such as BinduSMS) that provides SMS
            (text) services to various organizations/customers on behalf of ANS
            operators (Mobile, IPTSP, and PSTN). Aggregators act as wholesalers
            for ANS operators and bridge between the operator and the buyer
            organization.
          </ListItem>{" "}
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            <Text
              fontSize={{ base: "md", xl: "lg" }}
              as="span"
              fontWeight="bold"
            >
              Promotional SMS
            </Text>
            : A system-generated SMS (text) is sent to a customer on behalf of a
            brand or organization. This SMS provides information on the brand's
            marketing, services, and discounts.
          </ListItem>{" "}
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            <Text
              fontSize={{ base: "md", xl: "lg" }}
              as="span"
              fontWeight="bold"
            >
              Transactional SMS
            </Text>
            : Automated system generated text and voice SMS sent to the customer
            only when the customer is active in the case of a brand. This SMS
            provides the customer with any transaction, transfer confirmation,
            one-time password, etc.
          </ListItem>{" "}
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            <Text
              fontSize={{ base: "md", xl: "lg" }}
              as="span"
              fontWeight="bold"
            >
              Notification SMS
            </Text>
            : Automatically and actively notifies the customer about the
            account's activities, purchase confirmation and transfer
            notifications.
          </ListItem>{" "}
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            <Text
              fontSize={{ base: "md", xl: "lg" }}
              as="span"
              fontWeight="bold"
            >
              One-way SMS
            </Text>
            : Automatic system-generated text or SMS that is delivered to the
            customer. Promotional or transactional or notifications are provided
            through any system-generated SMS.
          </ListItem>{" "}
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            <Text
              fontSize={{ base: "md", xl: "lg" }}
              as="span"
              fontWeight="bold"
            >
              Two-way SMS
            </Text>
            : The subscriber responds in person or after receiving the SMS.
          </ListItem>{" "}
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            <Text
              fontSize={{ base: "md", xl: "lg" }}
              as="span"
              fontWeight="bold"
            >
              Toll-free SMS
            </Text>
            : No bill will be levied on the customer as per the demand of the
            customer organization, which the customer organization will pay.
          </ListItem>{" "}
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            <Text
              fontSize={{ base: "md", xl: "lg" }}
              as="span"
              fontWeight="bold"
            >
              Sender ID / Masking
            </Text>
            : A maximum of 11 character names, which the buyer can send to the
            customer via text SMS with his name or brand. Masking text SMS is
            only one-way SMS.
          </ListItem>{" "}
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            <Text
              fontSize={{ base: "md", xl: "lg" }}
              as="span"
              fontWeight="bold"
            >
              Group-based SMS
            </Text>
            : Any individual-specific area / profession / religious /
            educational / age based group / election manifesto etc. will be
            considered as normal group SMS.
          </ListItem>{" "}
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            <Text
              fontSize={{ base: "md", xl: "lg" }}
              as="span"
              fontWeight="bold"
            >
              Other SMS
            </Text>
            : Except for Person to Person (P2P) SMS, all Machine Generated SMS
            will be treated as A2P SMS.
          </ListItem>{" "}
        </UnorderedList>
      </Stack>

      <Stack>
        <Heading fontSize={{ base: "lg", xl: "xl" }}>
          Systems and Services
        </Heading>

        <OrderedList>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            As a listed aggregator of Bangladesh Telecommunication Regulatory
            Commission (BTRC), BinduSMS will provide or be obliged to provide
            A2P SMS service as per the prescribed tariff given by the
            Commission.
          </ListItem>

          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            As a listed aggregator, BinduSMS will be contracted with ANS
            Operator and MNP Service Provider to provide bulk SMS service. Our
            Terms and Conditions will contact individuals/organizations who have
            signed up for the Service in need.
          </ListItem>

          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            ANS operators may provide the same masking keyword to multiple
            aggregators subject to receiving required masking documents for
            sending SMS from the same organization. Customers may receive
            masking SMS services from various aggregators.
          </ListItem>
        </OrderedList>
      </Stack>

      <Stack>
        <Heading fontSize={{ base: "lg", xl: "xl" }}>
          SMS Services by BinduSMS
        </Heading>
        <Text fontSize={{ base: "md", xl: "lg" }}>
          As per the instructions of Bangladesh Telecommunication Regulatory
          Commission (BTRC), BinduSMS will provide the following SMS services as
          a listed SMS aggregator.
        </Text>

        <OrderedList>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            <Text
              fontSize={{ base: "md", xl: "lg" }}
              as="span"
              fontWeight="bold"
            >
              A2P SMS
            </Text>
            : Text, One-Way SMS, One Time Password (OTP), Pin Code, Promotional,
            Notification, Group-Based SMS, etc.
          </ListItem>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            Any legitimate and listed business entity / corporate institution /
            educational institution / financial institution/government
            institution / foreign institution operating in Bangladesh subject to
            government approval may receive bulk SMS service.
          </ListItem>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            All types of A2P SMS, including promotional SMS, must be sent in the
            “Bangla” language. However, in exceptional cases, you can send
            English letters/words/applications (OTP / Promo Code / Brand) with
            the prior permission of BTRC.
          </ListItem>

          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            To prevent religious incitement and anti-state SMS from reaching the
            general public and to prevent social unrest, A2P SMS
            aggregators/subscribers should vet the content of SMS from BTRC.
            Mobile operators and aggregators (BinduSMS) will also be required to
            filter all types of messages (Keywords) through their own filtering
            system as per BTRC guidelines; So that no unwanted SMS reaches the
            customer. The customer (sender) will be responsible for sending SMS
            outside the rules of the commission.
          </ListItem>

          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            BTRC, law enforcement agencies, NTMC, and BinduSMS will save or be
            able to keep the SMS content log of the customer for investigation
            or audit-related needs.
          </ListItem>
        </OrderedList>
      </Stack>

      <Stack>
        <Heading fontSize={{ base: "lg", xl: "xl" }}>Obligation</Heading>

        <OrderedList>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            Religiously provocative and anti-state SMS and no such SMS may be
            sent, which may disturb social peace.
          </ListItem>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            In the case of sending "Ordinary SMS", candidates for national or
            local elections cannot send SMS asking for votes mentioning a
            particular party and party symbol. However, if the candidate is
            elected, he will be able to provide an SMS stating what activities
            he will undertake for the people of his area.
          </ListItem>

          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            As an aggregator, BinduSMS will separate promotional and
            transactional SMS before sending A2P SMS through ANS operators and
            TAG or Flagging the transactional SMS, So that transactional SMS are
            not covered by the Do Not Disturb (DND) service.
          </ListItem>
        </OrderedList>
      </Stack>

      <Stack>
        <Heading fontSize={{ base: "lg", xl: "xl" }}>
          Account Cancellation
        </Heading>

        <OrderedList>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            If you send any SMS outside the prescribed rules of BTRC, A2P, and
            BinduSMS.
          </ListItem>

          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            If any information provided for receiving SMS service is proven to
            be fake/false.
          </ListItem>

          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            Sending religiously provocative and anti-state SMS and any such SMS
            can lead to social unrest.
          </ListItem>

          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            If you send an SMS on behalf of a foreign organization that does not
            have permission to conduct activities in Bangladesh or is not
            registered in Bangladesh.
          </ListItem>

          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            In the case of sending OTP SMS, the sender does not mention the
            organization's name or brand and sends OTP of any organization or
            brand other than the company contracted for the service.
          </ListItem>

          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            If any candidate in the national/local elections of Bangladesh sends
            an SMS to ask for votes by mentioning a particular party and party
            symbol without special permission.
          </ListItem>

          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            If you send an SMS on behalf of a business organization/apps/website
            etc., which is not valid according to the customary law of
            Bangladesh.
          </ListItem>

          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            Send private SMS (Adult Content) and advertise Adult Products which
            are prohibited to be bought / sold by the law or ministry in
            Bangladesh.
          </ListItem>

          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            If you fail to provide the required documents to open a new account.
          </ListItem>
        </OrderedList>
      </Stack>

      <Stack>
        <Heading fontSize={{ base: "lg", xl: "xl" }}>Termination</Heading>

        <OrderedList>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            Either party can terminate this Agreement at any time by notice to
            the other Party with or without cause.{" "}
          </ListItem>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            Either party may also terminate this Agreement with immediate effect
            by notice to the other party if:
          </ListItem>
          <OrderedList>
            <ListItem fontSize={{ base: "md", xl: "lg" }}>
              The other party becomes insolvent, makes any arrangement with or
              for the benefit of its creditors, goes into compulsory or
              voluntary liquidation, has a receiver, administrative receiver,
              liquidator, or other similar official appointed over its assets,
              is subject to an administration or similar order or ceases
              trading;
            </ListItem>
            <ListItem fontSize={{ base: "md", xl: "lg" }}>
              The other Party commits a material breach of the Agreement and
              (where such infringement is capable of remedy) fails to remedy the
              breach within 7 days of a written notice from the Party, not in
              breach requiring its remedy; or,
            </ListItem>
            <ListItem fontSize={{ base: "md", xl: "lg" }}>
              Any license required for Us to operate the Services is revoked,
              terminated, or modified or, in the case of new license
              requirements being imposed, the applicable license: Is not granted
              to Us, or Is granted to Us but in such a way as to prevent Us from
              continuing to make the Services available or a Network Operator
              from enabling Us to make the Services available.
            </ListItem>
          </OrderedList>

          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            We may terminate the Agreement immediately upon notice if any
            relevant legislation or regulation is implemented or modified with
            the effect that it is no longer commercially viable or possible for
            Us to make the Services available.
          </ListItem>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            Termination of the Agreement for any reason does not affect any
            rights that have accrued to either Party under the Agreement up to
            the date of its termination, and those terms and conditions of the
            Agreement that are by their nature capable of surviving termination
            will continue in full force and effect following such termination.
          </ListItem>
          <ListItem fontSize={{ base: "md", xl: "lg" }}>
            On termination of the agreement:
          </ListItem>
          <OrderedList>
            <ListItem fontSize={{ base: "md", xl: "lg" }}>
              You will immediately cease to use the services; and
            </ListItem>
            <ListItem fontSize={{ base: "md", xl: "lg" }}>
              Under or in connection with the agreement, all amounts then owed
              to us will become immediately due and payable.
            </ListItem>
            <ListItem fontSize={{ base: "md", xl: "lg" }}>
              You will forfeit any unused credit on your account, except for
              payments received by us within seven (7) days before termination.
            </ListItem>
            <ListItem fontSize={{ base: "md", xl: "lg" }}>
              All licenses and rights granted under these Terms will terminate
              immediately.
            </ListItem>
          </OrderedList>
        </OrderedList>
      </Stack>

      <Stack>
        <Heading fontSize={{ base: "lg", xl: "xl" }}>Copyright</Heading>
        <Text fontSize={{ base: "md", xl: "lg" }}>
          All BinduSMS logo, data, images, information, texts, programs, and
          images of advertisements placed on the site are registered of BinduSMS
          & subject to copyright laws. Any further processing or usage of any
          kind by third parties is not permitted.
        </Text>
      </Stack>
      <Stack>
        <Heading fontSize={{ base: "lg", xl: "xl" }}>
          Governing Law & Dispute Resolution
        </Heading>
        <Text fontSize={{ base: "md", xl: "lg" }}>
          These terms shall be governed by and construed by laws of Bangladesh
          and BTRC. The parties hereto submit to the non-exclusive jurisdiction
          of the Bangladesh High Courts to enforce any claim arising hereunder.
        </Text>
      </Stack>
      <Stack>
        <Heading fontSize={{ base: "lg", xl: "xl" }}>
          Summary Terms and Conditions
        </Heading>
        <Text fontSize={{ base: "md", xl: "lg" }}>
          You confirm that You hold the account corresponding to the data You
          have provided Bindulogic Limited (BinduSMS) with or that You have the
          account holder's permission to use this service.
        </Text>{" "}
      </Stack>
    </Stack>
  );
};
