import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { PromotionalSmsPolicyComponent } from "./promotional-sms-policy.component";

interface PromotionalSmsPolicyModalComponentProps {}

export const PromotionalSmsPolicyModalComponent: React.FC<
  PromotionalSmsPolicyModalComponentProps
> = (props: PromotionalSmsPolicyModalComponentProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  useEffect(() => {
    if (window.localStorage.getItem("policy-agreement") === null) {
      onOpen();
    }
  }, []);

  const handlePolicyAgreement = () => {
    window.localStorage.setItem("policy-agreement", "1");
    onClose();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size="xl"
        scrollBehavior="inside"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading fontSize={{ base: "2xl", lg: "3xl" }} py={2}>
              Promotional SMS Policy
            </Heading>
          </ModalHeader>
          <ModalBody px="8">
            <PromotionalSmsPolicyComponent modal={true} />
          </ModalBody>

          <ModalFooter>
            <Stack direction={"row"}>
              <Button
                colorScheme="blue"
                variant="outline"
                onClick={handlePolicyAgreement}
              >
                I Agree
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
