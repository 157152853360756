import { Stack, Text } from "@chakra-ui/react";
import React from "react";
import { PrivacyPolicyModalComponent } from "../../policy/components/privacy-policy-modal.component";
import { RefundPolicyModalComponent } from "../../policy/components/refund-policy-modal.component";
import { TermsOfServiceModalComponent } from "../../policy/components/terms-of-service-modal.component";

interface PolicyFooterComponentProps {}

export const PolicyFooterComponent: React.FC<PolicyFooterComponentProps> = (
  props: PolicyFooterComponentProps
) => {
  return (
    <Stack>
      <Text color="gray.500">
        <TermsOfServiceModalComponent /> · <PrivacyPolicyModalComponent /> ·{" "}
        <RefundPolicyModalComponent />
      </Text>
    </Stack>
  );
};
