import {
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList
} from "@chakra-ui/react";
import React from "react";

interface PrivacyPolicyComponentProps {
  modal?: boolean;
}

export const PrivacyPolicyComponent: React.FC<PrivacyPolicyComponentProps> = (
  props: PrivacyPolicyComponentProps
) => {
  return (
    <Stack id="privacy-policy" spacing="12" pt={props.modal ? 0 : 12}>
      {!props.modal ? (
        <Heading fontSize={{ base: "2xl", xl: "3xl" }}>Privacy Policy</Heading>
      ) : (
        <></>
      )}
      <Stack>
        <Text fontSize={{ base: "md", xl: "lg" }}>
          Please read the following to learn more about our privacy policy. The
          user is advised to please read the privacy policy carefully. By
          accessing the services provided by BinduSMS by Bindulogic Limited
          through sms.bindulogic.com by Bindulogic Limited, mobile app or
          affiliates users agree to the collection and use of user's data by
          BinduSMS by Bindulogic Limited in the manner provided in this Privacy
          Policy. If the user has questions or concerns regarding this statement
          then the user can contact BinduSMS by Bindulogic Limited at
          contact@bindulogic.com
        </Text>
      </Stack>

      <Stack>
        <Heading fontSize={{ base: "lg", xl: "xl" }}>
          What Information Do We Collect?
        </Heading>

        <UnorderedList fontSize={{ base: "md", xl: "lg" }}>
          <ListItem>Billing Address</ListItem>{" "}
          <ListItem>Contact Information (including e-mail address)</ListItem>
          <ListItem>Mobile phone number and network details</ListItem>
          <ListItem>
            Information relevant to customer surveys and offers
          </ListItem>
          <ListItem>
            Information you provide us, such as your name or ordering
            information when you register or any e-mail messages you send us
          </ListItem>
        </UnorderedList>
      </Stack>

      <Stack>
        <Heading fontSize={{ base: "lg", xl: "xl" }}>
          Identifiable Information
        </Heading>
        <UnorderedList fontSize={{ base: "md", xl: "lg" }}>
          <ListItem>Name (including first name and last name)</ListItem>
          <ListItem>E-mail address</ListItem>
          <ListItem>Mobile phone number and contact/billing details</ListItem>
          <ListItem>Demographic profile (like age and gender)</ListItem>
        </UnorderedList>
      </Stack>

      <Stack>
        <Heading fontSize={{ base: "lg", xl: "xl" }}>
          Use of Mobile & Email Address
        </Heading>
        <UnorderedList fontSize={{ base: "md", xl: "lg" }}>
          <ListItem>For identifying users</ListItem>
          <ListItem>
            Providing confirmation on registration and password reminders
          </ListItem>
          <ListItem>
            Promotional SMS, MMS, newsletters and voice calls on behalf of
            BinduSMS by Bindulogic Ltd. and its alliance partners{" "}
          </ListItem>
          <ListItem>
            Notifying any changes in service policy or terms of use
          </ListItem>
        </UnorderedList>
      </Stack>

      <Stack>
        <Heading fontSize={{ base: "lg", xl: "xl" }}>
          Use of Personal Information
        </Heading>
        <UnorderedList fontSize={{ base: "md", xl: "lg" }}>
          <ListItem>For providing personalized features</ListItem>
          <ListItem>
            Connecting with the user in the case of password retrieval and
            policy changes
          </ListItem>
          <ListItem>Providing the services requested by the user</ListItem>
          <ListItem>
            Preserving social history as governed by existing law or policy
          </ListItem>
        </UnorderedList>
      </Stack>

      <Stack>
        <Heading fontSize={{ base: "lg", xl: "xl" }}>
          Information Sharing & Disclosure
        </Heading>
        <UnorderedList fontSize={{ base: "md", xl: "lg" }}>
          <ListItem>
            BinduSMS by Bindulogic Limited do not share or rent user email
            addresses or mobile number with third parties except our alliance
            partners.
          </ListItem>
          <ListItem>
            Connecting with the user in the case of password retrieval and
            policy changes
          </ListItem>
          <ListItem>
            BinduSMS by Bindulogic Limited responds to requests from any
            Bangladesh or foreign government, security, defense, revenue,
            regulatory or other authority, agency or officer.
          </ListItem>
          <ListItem>
            BinduSMS by Bindulogic Limited reserve the right to disclose user
            personally identifiable and email address information as required by
            law and when BinduSMS by Bindulogic Limited believes that disclosure
            is necessary to protect our rights and/or comply with a judicial
            proceeding, court order, or legal process served on our Website/App.
          </ListItem>
        </UnorderedList>
      </Stack>

      <Stack>
        <Heading fontSize={{ base: "lg", xl: "xl" }}>Cookie Usage</Heading>
        <Text fontSize={{ base: "md", xl: "lg" }}>
          A cookie is a small file that asks permission to be placed on your
          computer's hard drive. Once you agree, the file is added, and the
          cookie helps analyze web traffic or lets You know when You visit a
          particular site. Cookies allow web applications to respond to You as
          an individual. The web application can tailor its operations to Your
          needs, likes, and dislikes by gathering and remembering information
          about your preferences. We use traffic log cookies to identify which
          pages are being used. This helps Us analyze data about web page
          traffic and improve Our website in order to tailor it to customer
          needs. We only use this information for statistical analysis purposes,
          and then the data is removed from the system. Overall, cookies help us
          provide You with a better website by enabling Us to monitor which
          pages You find useful and which You do not. A cookie in no way gives
          Us access to Your computer or any information about You other than the
          data You choose to share with Us. You can choose to accept or decline
          cookies. Most web browsers automatically accept cookies, but you can
          usually modify your browser setting to decline cookies if you prefer.
          This may prevent you from taking full advantage of the website.
        </Text>
      </Stack>

      <Stack>
        <Heading fontSize={{ base: "lg", xl: "xl" }}>External Links</Heading>
        <Text fontSize={{ base: "md", xl: "lg" }}>
          Our website also refers to third parties. As a rule, these are
          identified by stating the relevant Internet address or the
          company/product logo. BinduSMS has no influence whatsoever on the
          contents and design of sites of other providers and, therefore, cannot
          extend the warranties under this declaration on data protection to
          such sites, nor do we refer that we adopt their contents as our own.
        </Text>
      </Stack>

      <Stack>
        <Heading fontSize={{ base: "lg", xl: "xl" }}>
          Control of Personal Information
        </Heading>
        <Text fontSize={{ base: "md", xl: "lg" }}>
          You may choose to restrict the collection or use of Your personal
          information in the following ways:
        </Text>
        <UnorderedList fontSize={{ base: "md", xl: "lg" }}>
          <ListItem>
            Whenever you are asked to fill in a form on the website, look for
            the box that indicates that you do not want your information to be
            used by anybody for direct marketing purposes.
          </ListItem>
          <ListItem>
            If you have previously agreed with us on using your personal
            information for direct markiting purposes, you may change your mind
            at any time by writing to us or emailing us.
          </ListItem>
        </UnorderedList>

        <Text textStyle="italic" fontSize={{ base: "md", xl: "lg" }}>
          We will not sell, distribute or lease your personal information to
          third parties unless we have Your permission or are required by law to
          do so. We may use your personal information to send you promotional
          information about third parties, which we think you may find
          interesting if you tell us that you wish this to happen.
        </Text>
      </Stack>

      <Stack>
        <Heading fontSize={{ base: "lg", xl: "xl" }}>Security</Heading>
        <Text fontSize={{ base: "md", xl: "lg" }}>
          BinduSMS by Bindulogic Limited follow generally accepted industry
          standards to protect the personal information and email address,
          mobile number submitted to us, both during transmission and once
          BinduSMS by Bindulogic Limited receives it. The user's BinduSMS by
          Bindulogic Limited Account is password protected. No method of
          transmission over the Internet, or method of electronic storage, is
          100% secure, however. Therefore, while BinduSMS by Bindulogic Limited
          strives to use commercially acceptable means to protect user personal
          information, SMS logs, email address, and mobile number, BinduSMS by
          Bindulogic Limited cannot guarantee its absolute security. If the user
          has any questions about security on our Website then send email us at
          contact@bindulogic.com
        </Text>
      </Stack>
    </Stack>
  );
};
