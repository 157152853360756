import React from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  PaginationState,
  useReactTable
} from "@tanstack/react-table";
import {
  Box,
  Button,
  Center,
  Select,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { Transaction } from "../../../api/type";
import { useGetSmsTransactionHistoryQuery } from "../../../api";

interface SmsTransactionTableComponentProps {}

export const SmsTransactionTableComponent: React.FC<
  SmsTransactionTableComponentProps
> = (props: SmsTransactionTableComponentProps) => {
  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10
    });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );

  const { data, isLoading } = useGetSmsTransactionHistoryQuery({
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize
  });
  const columnHelper = createColumnHelper<Transaction>();

  const columns: ColumnDef<Transaction, any>[] = [
    columnHelper.accessor((row) => row.id, {
      id: "id",
      header: () => "ID",
      cell: (row) => row.getValue()
    }),

    columnHelper.accessor((row) => row.TransactionType, {
      id: "type",
      header: () => "Transaction Type",
      cell: (row) => {
        if (row.getValue() === "smsCost") {
          return "SMS Cost";
        } else if (row.getValue() === "smsRefund") {
          return "SMS Refund";
        } else if (row.getValue() === "smsBalanceRecharge") {
          return "Balance Recharge";
        } else {
          return "Unknown Type";
        }
      }
    }),

    columnHelper.accessor((row) => row.amount, {
      id: "amount",
      header: () => "Amount",
      cell: (row) => {
        return `৳ ${row.getValue()}`;
      }
    }),

    columnHelper.accessor((row) => row.details?.timestamp, {
      id: "timestamp",
      header: () => "Date",
      cell: (row) => {
        dayjs.extend(utc);
        dayjs.extend(tz);

        return dayjs(row.getValue())
          .tz("Asia/Dhaka")
          .format("DD-MMM-YYYY hh:mm A")
          .toString();
      }
    })
  ];

  const table = useReactTable({
    data: data ? data.smsTransactionList : [],
    columns: columns,
    pageCount: data
      ? Math.ceil(data.smsTransactionListSize / pagination.pageSize)
      : 0,
    state: {
      pagination
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true
  });

  if (!isLoading) {
    return data?.smsTransactionList.length ? (
      <Stack>
        <Table variant="striped" colorScheme="gray">
          <Thead backgroundColor="gray.700" color="white">
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th key={header.id} colSpan={header.colSpan} color="white">
                      {header.isPlaceholder ? null : (
                        <Box>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </Box>
                      )}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <Stack
          direction={{ base: "column", lg: "row" }}
          justify="space-between"
          alignItems="center"
        >
          <Box width={{ base: "100%", lg: "70%" }}>
            <Text fontSize="md" fontWeight="bold">
              Showing {pageIndex * pageSize + 1} -{" "}
              {Math.min(
                (pageIndex + 1) * pageSize + 1,
                data ? data.smsTransactionListSize : 0
              )}{" "}
              of {data ? data.smsTransactionListSize : 0} (Page{" "}
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()})
            </Text>{" "}
          </Box>

          <Stack
            direction="row"
            width={{ base: "100%", lg: "30%" }}
            justifyContent="space-between"
          >
            <Stack
              direction="row"
              width={{ base: "50%", md: "70%", lg: "80%" }}
            >
              <Button
                colorScheme="blue"
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                {"<<"}
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                {"<"}
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                {">"}
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                {">>"}
              </Button>{" "}
            </Stack>

            <Box width={{ base: "50%", md: "30%", lg: "20%" }}>
              <Select
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 25, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Select>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    ) : (
      <Center height={"15vh"}>
        <Text>No data found.</Text>
      </Center>
    );
  } else {
    return (
      <Center height="15vh">
        <Spinner size={"xl"} />
      </Center>
    );
  }
};
