import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../config";
import * as ApiTypes from "./type";

const baseQuery = fetchBaseQuery({
  baseUrl: env.apiEndpoint,
  credentials: "include",
  prepareHeaders: (headers, { getState }: any) => {
    const accessToken = window.localStorage.getItem("jwt");
    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }

    return headers;
  }
});

export const api = createApi({
  reducerPath: "api",
  baseQuery,
  tagTypes: [
    "Masking",
    "Balance",
    "User",
    "User List",
    "Rate",
    "SMS History",
    "API Token",
    "Recharge Transaction History",
    "SMS Transaction History"
  ],
  endpoints: (builder) => ({
    /* Admin API Routes*/
    addUserMasking: builder.mutation<
      ApiTypes.AddUserMaskingResponse,
      ApiTypes.AddUserMaskingRequest
    >({
      query: ({ userId, mask }) => ({
        url: "/user-masking/add",
        method: "POST",
        body: { userId, mask }
      }),
      invalidatesTags: ["Masking"]
    }),

    addUserBalance: builder.mutation<
      ApiTypes.AddUserBalanceResponse,
      ApiTypes.AddUserBalanceRequest
    >({
      query: ({ userId, amount }) => ({
        url: "/balance/add",
        method: "POST",
        body: { userId, amount }
      }),
      invalidatesTags: ["Balance"]
    }),

    deactivateUser: builder.mutation<
      ApiTypes.DeactivateUserResponse,
      ApiTypes.DeactivateUserRequest
    >({
      query: ({ userId }) => ({
        url: "/user/deactivate",
        method: "POST",
        body: { userId }
      }),
      invalidatesTags: ["User List"]
    }),

    resetUserPassword: builder.mutation<
      ApiTypes.ResetUserPasswordResponse,
      ApiTypes.ResetUserPasswordRequest
    >({
      query: ({ userId, newPassword }) => ({
        url: "/user/reset-password",
        method: "POST",
        body: { userId, newPassword }
      })
    }),

    updateUserRate: builder.mutation<
      ApiTypes.UpdateUserRateResponse,
      ApiTypes.UpdateUserRateRequest
    >({
      query: ({ userId, smsType, rate }) => ({
        url: "/user/update-rate",
        method: "POST",
        body: { userId, smsType, rate }
      }),
      invalidatesTags: ["Rate"]
    }),

    deleteUserMasking: builder.mutation<
      ApiTypes.DeleteUserMaskingResponse,
      ApiTypes.DeleteUserMaskingRequest
    >({
      query: ({ userId, mask }) => ({
        url: "/user-masking/delete",
        method: "POST",
        body: { userId, mask }
      })
    }),

    getUser: builder.query<ApiTypes.GetUserResponse, ApiTypes.GetUserRequest>({
      query: ({ search }) => ({
        url: "/user",
        method: "GET",
        body: { search }
      }),
      providesTags: ["User List"]
    }),

    /* User API Routes */
    sendSingleSms: builder.mutation<
      ApiTypes.SendSingleSmsResponse,
      ApiTypes.SendSingleSmsRequest
    >({
      query: ({ sms, campaignName }) => ({
        url: "/send-sms",
        method: "POST",
        body: { sms, campaignName }
      }),
      invalidatesTags: ["SMS Transaction History", "Balance", "SMS History"]
    }),

    sendBulkSms: builder.mutation<
      ApiTypes.SendBulkSmsResponse,
      ApiTypes.SendBulkSmsRequest
    >({
      query: ({ smsList, campaignName }) => ({
        url: "/send-bulk-sms",
        method: "POST",
        body: { smsList, campaignName }
      }),
      invalidatesTags: ["SMS Transaction History", "Balance", "SMS History"]
    }),

    register: builder.mutation<
      ApiTypes.RegisterResponse,
      ApiTypes.RegisterRequest
    >({
      query: ({ name, password, phoneNumber }) => ({
        url: "/register",
        method: "POST",
        body: { name, password, phoneNumber }
      }),
      invalidatesTags: ["User"]
    }),

    login: builder.mutation<ApiTypes.LoginResponse, ApiTypes.LoginRequest>({
      query: ({ phone, password }) => ({
        url: "/login",
        method: "POST",
        body: { phone, password }
      }),
      invalidatesTags: ["User"]
    }),

    getMe: builder.query<ApiTypes.GetMeResponse, ApiTypes.GetMeRequest>({
      query: () => ({
        url: "/user/me",
        method: "GET"
      }),
      providesTags: ["User"]
    }),

    getApiToken: builder.query<
      ApiTypes.GetApiTokenResponse,
      ApiTypes.GetApiTokenRequest
    >({
      query: () => ({
        url: "/user/api-token",
        method: "GET"
      }),
      providesTags: ["API Token"]
    }),

    getCampaignHistory: builder.query<
      ApiTypes.GetCampaignHistoryResponse,
      ApiTypes.GetCampaignHistoryRequest
    >({
      query: ({ pageIndex, pageSize }) => ({
        url: `/sms-campaign?pageIndex=${pageIndex}&pageSize=${pageSize}`,
        method: "GET"
      }),
      providesTags: (result, error, arg) => [
        {
          type: "SMS History",
          pageIndex: arg.pageIndex,
          pageSize: arg.pageSize
        }
      ]
    }),

    getMaskingList: builder.query<
      ApiTypes.GetMaskingListResponse,
      ApiTypes.GetMaskingListRequest
    >({
      query: () => ({
        url: "/user-masking/list",
        method: "GET"
      }),
      providesTags: ["Masking"]
    }),

    getBalance: builder.query<
      ApiTypes.GetBalanceResponse,
      ApiTypes.GetBalanceRequest
    >({
      query: () => ({
        url: "/balance/get",
        method: "GET"
      }),
      providesTags: ["Balance"]
    }),

    getRateList: builder.query<
      ApiTypes.GetRateListResponse,
      ApiTypes.GetRateListRequest
    >({
      query: () => ({
        url: "/rate/list",
        method: "GET"
      }),
      providesTags: ["Rate"]
    }),

    generateTotp: builder.mutation<
      ApiTypes.GenerateTotpResponse,
      ApiTypes.GenerateTotpRequest
    >({
      query: () => ({
        url: `/user/totp/generate`,
        method: `POST`
      })
    }),

    verifyTotp: builder.mutation<
      ApiTypes.VerifyTotpResponse,
      ApiTypes.VerifyTotpRequest
    >({
      query: ({ totp }) => ({
        url: `/user/totp/verify`,
        method: `POST`,
        body: { totp }
      }),
      invalidatesTags: [
        "User",
        "Balance",
        "SMS History",
        "SMS Transaction History",
        "Recharge Transaction History"
      ]
    }),

    rechargeBalance: builder.mutation<
      ApiTypes.RechargeBalanceResponse,
      ApiTypes.RechargeBalanceRequest
    >({
      query: ({ amount }) => ({
        url: `/invoice/generate`,
        method: `POST`,
        body: { amount }
      }),
      invalidatesTags: ["Balance", "Recharge Transaction History"]
    }),

    getSmsTransactionHistory: builder.query<
      ApiTypes.GetSmsTransactionHistoryResponse,
      ApiTypes.GetSmsTransactionHistoryRequest
    >({
      query: ({ pageIndex, pageSize }) => ({
        url: `/user/sms-transaction-history?pageIndex=${pageIndex}&pageSize=${pageSize}`,
        method: "GET"
      }),
      providesTags: (result, error, arg) => [
        {
          type: "SMS Transaction History",
          pageIndex: arg.pageIndex,
          pageSize: arg.pageSize
        }
      ]
    }),

    getRechargeTransactionHistory: builder.query<
      ApiTypes.GetRechargeTransactionHistoryResponse,
      ApiTypes.GetRechargeTransactionHistoryRequest
    >({
      query: ({ pageIndex, pageSize }) => ({
        url: `/user/recharge-history?pageIndex=${pageIndex}&pageSize=${pageSize}`,
        method: "GET"
      }),
      providesTags: (result, error, arg) => [
        {
          type: "Recharge Transaction History",
          pageIndex: arg.pageIndex,
          pageSize: arg.pageSize
        }
      ]
    })
  })
});

export const {
  useAddUserMaskingMutation,
  useAddUserBalanceMutation,
  useDeactivateUserMutation,
  useResetUserPasswordMutation,
  useUpdateUserRateMutation,
  useDeleteUserMaskingMutation,
  useGetUserQuery,
  useSendSingleSmsMutation,
  useSendBulkSmsMutation,
  useRegisterMutation,
  useLoginMutation,
  useGetMeQuery,
  useGetApiTokenQuery,
  useGetMaskingListQuery,
  useGetBalanceQuery,
  useGetRateListQuery,
  useGetCampaignHistoryQuery,
  useGenerateTotpMutation,
  useVerifyTotpMutation,
  useRechargeBalanceMutation,
  useGetRechargeTransactionHistoryQuery,
  useGetSmsTransactionHistoryQuery
} = api;
