import { Flex, Stack } from "@chakra-ui/react";

import { useDocumentTitle } from "../../../hooks/use-document-title.hook";
import { PageTransitionComponent } from "../../core/components/page-transition.component";
import { DecorationImageComponent } from "../components/decoration-image.component";
import { RegisterFormComponent } from "../components/register-form.component";

export const RegisterPage = () => {
  useDocumentTitle("Register | BinduSMS");

  return (
    <PageTransitionComponent>
      <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
        <Flex flex={1} p={8} align="center" justify="center">
          <RegisterFormComponent />
        </Flex>
        <Flex flex={1}>
          <DecorationImageComponent />
        </Flex>
      </Stack>
    </PageTransitionComponent>
  );
};
