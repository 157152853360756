import { useToast } from "@chakra-ui/react";
import { Navigate, Outlet } from "react-router-dom";

interface AuthRouteComponentProps {}

export const AuthRouteComponent: React.FC<AuthRouteComponentProps> = (
  props: AuthRouteComponentProps
) => {
  const isAuthenticated = window.localStorage.getItem("jwt") !== null;
  const toast = useToast();

  if (isAuthenticated) {
    toast({
      title: "Already logged in",
      description: "",
      status: "info",
      position: "top",
      duration: 1000,
      isClosable: true
    });
    return <Navigate to="/" />;
  } else {
    return <Outlet />;
  }
};
