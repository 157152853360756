import React from "react";
import { useLocation } from "react-router-dom";

import { Center } from "@chakra-ui/layout";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  ExpandedIndex,
  Icon
} from "@chakra-ui/react";

import { IDashboard, IPage, IPageGroup } from "../../../routes/route.type";
import { dashboardTheme } from "../../../theme/dashboard/dashboard.theme";
import { NavItemComponent } from "./nav-item.component";

interface Props {
  dashboard: IDashboard;
}

const SidebarContent: React.FC<Props> = (props: Props) => {
  // Open the accordion corresponding to current link
  const location = useLocation();
  let currentLinkIndex: ExpandedIndex = [];
  props.dashboard.content.map((content) => {
    if ("pages" in content) {
      return content.pages;
    } else {
      return content;
    }
  });

  props.dashboard.content.forEach((pageGroup, index) => {
    if ("pages" in pageGroup) {
      pageGroup.pages.forEach((page) => {
        if (location.pathname === page.link) {
          currentLinkIndex = [index];
        }
      });
    } else {
      if (location.pathname === pageGroup.link) {
        currentLinkIndex = [index];
      }
    }
  });

  return (
    <Box
      as="nav"
      pos="fixed"
      top="0"
      left="0"
      zIndex="sticky"
      h="full"
      shadow="sm"
      overflowX="hidden"
      overflowY="scroll"
      bg={dashboardTheme.sidebar.backgroundColor}
      borderColor={dashboardTheme.sidebar.borderColor}
      borderRightWidth={{ base: 0, lg: 1 }}
      w={dashboardTheme.sidebar.width}
      sx={{
        "&::-webkit-scrollbar": {
          width: "6px"
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
          backgroundColor: "gray.700"
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "gray.600",
          borderRadius: "12px"
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "gray.500"
        }
      }}
    >
      <Box m={8}>
        <Center>
          <Box width={32}>{props.dashboard.logo}</Box>
        </Center>
      </Box>

      <Accordion
        aria-label="Navigation"
        px={3}
        color={dashboardTheme.sidebar.backgroundColor}
        borderColor={dashboardTheme.sidebar.dividerColor}
        defaultIndex={currentLinkIndex}
      >
        {props.dashboard.content.map((pageGroup: IPageGroup | IPage) => {
          if ("pages" in pageGroup) {
            return (
              <AccordionItem key={pageGroup.header + "-section"}>
                <AccordionButton
                  textTransform="uppercase"
                  color={dashboardTheme.sidebar.headerColor}
                  fontSize="sm"
                  fontWeight="bold"
                  py={3}
                  borderRadius="md"
                  _expanded={{ color: "gray.400" }}
                >
                  <Icon mr="2" boxSize="4" as={pageGroup.icon} />
                  <Box flex="1" textAlign="left">
                    {pageGroup.header}
                  </Box>
                  <AccordionIcon fontSize={"2xl"} />
                </AccordionButton>
                <AccordionPanel px={0}>
                  {pageGroup.pages.map((page) => {
                    return (
                      <NavItemComponent
                        key={page.name + "-page-link"}
                        page={page}
                      />
                    );
                  })}
                </AccordionPanel>
              </AccordionItem>
            );
          } else {
            return (
              <NavItemComponent
                key={pageGroup.name + "-page-link"}
                page={pageGroup}
              />
            );
          }
        })}
      </Accordion>
    </Box>
  );
};

export default SidebarContent;
