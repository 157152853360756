import { AnimatePresence } from "framer-motion";
import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { DashboardSkeletonComponent } from "../modules/core/components/dashboard-skeleton.component";
import { PageContainerComponent } from "../modules/core/components/page-container.component";
import { MissingPage } from "../modules/core/pages/missing.page";
import { AuthRouteComponent } from "./components/auth-route.component";
import { dashboardList } from "./dashboards/dashboard.route";
import { PrivateRouteComponent } from "./components/private-route.component";
import { publicRoutes } from "./public.route";
import { IPage, IPageGroup } from "./route.type";
import { privateRoutes } from "./private.route";

export const AppRoute: React.FC = () => {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes key={location.pathname}>
        {/* Public Routes */}
        <Route element={<AuthRouteComponent />}>
          {publicRoutes.pages.map((page) => {
            return (
              <Route key={page.name} path={page.link} element={page.content} />
            );
          })}
        </Route>
        {/* All Dashboard Routes */}

        {dashboardList.map((dashboard) => {
          return (
            <Route
              key={dashboard.dashboardName}
              element={<PrivateRouteComponent role={dashboard.dashboardName} />}
            >
              <Route
                element={<DashboardSkeletonComponent dashboard={dashboard} />}
              >
                {dashboard.content.map((pageGroup: IPage | IPageGroup) => {
                  if ("pages" in pageGroup) {
                    return pageGroup.pages.map((page) => {
                      return (
                        <Route
                          path={page.link}
                          key={pageGroup.header + "-" + page.name}
                          element={
                            <PageContainerComponent
                              pageGroup={pageGroup}
                              page={page}
                            />
                          }
                        />
                      );
                    });
                  } else {
                    return (
                      <Route
                        path={pageGroup.link}
                        key={pageGroup.name}
                        element={
                          <PageContainerComponent
                            pageGroup={null}
                            page={pageGroup}
                          />
                        }
                      />
                    );
                  }
                })}
              </Route>{" "}
              {privateRoutes.pages.map((page) => {
                return (
                  <Route
                    key={page.name}
                    path={page.link}
                    element={page.content}
                  />
                );
              })}
              <Route path="/" element={<Navigate to="/status" />} />
            </Route>
          );
        })}

        {/* 404 Page */}
        <Route path="*" element={<MissingPage />} />
      </Routes>
    </AnimatePresence>
  );
};
