import { Image } from "@chakra-ui/react";
import React from "react";

interface BindusmsLogoComponentProps {}

export const BindusmsLogoComponent: React.FC<BindusmsLogoComponentProps> = (
  props: BindusmsLogoComponentProps
) => {
  return <Image width={32} objectFit="cover" src="/images/bindusms-logo.png" />;
};
