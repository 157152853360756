import {
  Badge,
  Button,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Tr,
  useClipboard
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useGetApiTokenQuery, useGetMeQuery } from "../../../api";

interface DeveloperPageProps {}

export const DeveloperPage: React.FC<DeveloperPageProps> = (
  props: DeveloperPageProps
) => {
  const { data } = useGetApiTokenQuery({});

  const getMeResult = useGetMeQuery({});
  const [apiKey, setApiKey] = useState<string>("");
  const { onCopy, hasCopied } = useClipboard(apiKey);

  useEffect(() => {
    if (data) {
      setApiKey(data.token);
    }
  }, [data]);

  return (
    <>
      {getMeResult.data && getMeResult.data.user.isActive ? (
        <Stack spacing={4}>
          <Text>
            Use this API key in your application to send SMS from your
            application directly
          </Text>
          <Stack
            direction="row"
            justify={"space-between"}
            width={{ base: "100%", lg: "75%", xl: "50%" }}
          >
            <InputGroup size="md">
              <InputLeftAddon children={<Text>API Key</Text>} />
              <Input
                variant="filled"
                colorScheme={"gray"}
                value={apiKey}
                borderWidth={0}
                fontWeight="bold"
                _focus={{
                  bgColor: "gray.100",
                  borderWidth: 0
                }}
              />
              <InputRightElement width="4.5rem">
                <Button size="sm" onClick={onCopy} colorScheme="blue">
                  {hasCopied ? "Copied" : "Copy"}
                </Button>
              </InputRightElement>
            </InputGroup>
          </Stack>
          <Text color="gray.500" mb={5}>
            Keep this API key secret
          </Text>
          <br />
          <Stack spacing={4} width={{ base: "100%", lg: "75%", xl: "50%" }}>
            <Heading as="h3" size="md">
              <Badge mr={3} fontSize="md" p="3" colorScheme="green">
                GET
              </Badge>
              Send SMS
            </Heading>

            <InputGroup size="md">
              <Textarea
                variant="filled"
                colorScheme={"gray"}
                value={`https://smsapi.bindulogic.com/send-sms?token=0bf0451d22cf21b8ebed8b7b67a40f9b&to=01711222333&message="Hello World"`}
                borderWidth={0}
                rows={3}
                fontWeight="bold"
                _focus={{
                  bgColor: "gray.100",
                  borderWidth: 0
                }}
              />
              <InputRightElement width="4.5rem"></InputRightElement>
            </InputGroup>

            <Flex>
              <Heading fontSize="lg">Endpoint</Heading>
            </Flex>

            <TableContainer>
              <Table variant="simple" size="sm">
                <Tbody>
                  <Tr>
                    <Th>HTTP URL</Th>
                    <Td>https://smsapi.bindulogic.com/send-sms</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
            <Heading fontSize="lg">Params</Heading>

            <TableContainer>
              <Table variant="simple" size="sm">
                <Tbody>
                  <Tr>
                    <Th>Token</Th>
                    <Td>{data?.token}</Td>
                  </Tr>
                  <Tr>
                    <Th>To</Th>
                    <Td>01711222333</Td>
                  </Tr>
                  <Tr>
                    <Th>Message</Th>
                    <Td>Hello World</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </Stack>
        </Stack>
      ) : (
        <Text>Please activate your account to avail this feature.</Text>
      )}
    </>
  );
};
