import { Stack, Text } from "@chakra-ui/react";
import React from "react";

interface ContactAddressComponentProps {}

export const ContactAddressComponent: React.FC<ContactAddressComponentProps> = (
  props: ContactAddressComponentProps
) => {
  return (
    <Stack>
      <Text color="gray.500" fontSize="xs">
        Address: House 07, Road 02, Block B, Mirpur 10, Dhaka 1216 | Contact:
        01302606005
      </Text>
    </Stack>
  );
};
