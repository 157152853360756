import { Stack, StackDivider } from "@chakra-ui/react";
import { RechargeTransactionTableComponent } from "../components/recharge-transaction-table.component";

export const RechargeHistoryTab = () => {
  return (
    <Stack spacing={"2"} divider={<StackDivider borderColor="gray.200" />}>
      <RechargeTransactionTableComponent />
    </Stack>
  );
};
