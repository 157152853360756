import { Container, Stack, Tag, Text, Wrap, WrapItem } from "@chakra-ui/react";
import React from "react";

interface SmsPreviewComponentProps {
  from: string;
  to: string;
  content: string;
}

export const SmsPreviewComponent: React.FC<SmsPreviewComponentProps> = (
  props: SmsPreviewComponentProps
) => {
  return (
    <Stack p={2} borderColor="gray.200" borderWidth={1} borderRadius="md">
      <Wrap direction="row">
        <WrapItem>
          <Text> From </Text>
        </WrapItem>
        <WrapItem>
          <Tag colorScheme={"blue"}>{props.from}</Tag>
        </WrapItem>
        <WrapItem>
          <Text> to </Text>
        </WrapItem>
        <WrapItem>
          <Tag colorScheme={"blue"}> {props.to}</Tag>
        </WrapItem>
      </Wrap>
      <Container p="0" m="0" width="100%">
        {props.content.length !== 0 ? (
          <Text
            borderRadius={"md"}
            p={4}
            width="100%"
            bgColor="blue.50"
            overflowWrap={"break-word"}
            whiteSpace="pre-line"
          >
            {props.content}
          </Text>
        ) : (
          <Text borderRadius={"md"} p={4} bgColor="gray.100" color="gray.500">
            No Content
          </Text>
        )}{" "}
      </Container>{" "}
    </Stack>
  );
};
