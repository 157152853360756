import {
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from "@chakra-ui/react";
import React from "react";
import { BalanceAndBillsComponent } from "../components/balance-and-bills.component";
import { RechargeHistoryTab } from "../tabs/recharge-history.tab";
import { SmsCampaignTab } from "../tabs/sms-campaign.tab";
import { TransactionHistoryTab } from "../tabs/transaction-history.tab";

interface StatusPageProps {}

export const StatusPage: React.FC<StatusPageProps> = (
  props: StatusPageProps
) => {
  return (
    <Stack spacing={4}>
      <BalanceAndBillsComponent />
      <Tabs variant="enclosed-colored" colorScheme="blue">
        <TabList>
          <Tab>SMS Campaign History</Tab>
          <Tab>Transaction History</Tab>
          <Tab>Recharge History</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <SmsCampaignTab />
          </TabPanel>{" "}
          <TabPanel px={0}>
            <TransactionHistoryTab />
          </TabPanel>
          <TabPanel px={0}>
            <RechargeHistoryTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
};
