import React from "react";

import { Box, Heading, Stack } from "@chakra-ui/layout";

import { IPage, IPageGroup } from "../../../routes/route.type";
import { PageTransitionComponent } from "./page-transition.component";
import { PathBreadcrumbComponent } from "./path-breadcrumb.component";
import { useDocumentTitle } from "../../../hooks/use-document-title.hook";
import { DeactivatedAccountComponent } from "../../auth/components/deactivated-account.component";
import { BindulogicCreditsComponent } from "./bindulogic-credits.component";
import { PolicyFooterComponent } from "./policy-footer.component";

interface Props {
  pageGroup: IPageGroup | null;
  page: IPage;
}

export const PageContainerComponent: React.FC<Props> = (props: Props) => {
  useDocumentTitle(props.page.name + " | BinduSms");

  return (
    <PageTransitionComponent>
      <Box px={{ base: 6, lg: 12 }} py={{ base: 2, lg: 4 }}>
        <Stack spacing={2} minHeight="84vh">
          <DeactivatedAccountComponent />
          <Heading pb={{ base: 4, lg: 8 }}>{props.page.name}</Heading>
          {props.pageGroup ? (
            <PathBreadcrumbComponent
              crumbs={[
                {
                  name: props.pageGroup.header,
                  link: props.pageGroup.pages[0].link
                },
                {
                  name: props.page.name,
                  link: props.page.link
                }
              ]}
            />
          ) : (
            <></>
          )}

          {props.page.content}
        </Stack>
        <BindulogicCreditsComponent />
      </Box>
    </PageTransitionComponent>
  );
};
