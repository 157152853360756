import { FailedPaymentPage } from "../modules/recharge/pages/failed-payment.page";
import { SuccessfulPaymentPage } from "../modules/recharge/pages/successful-payment.page";

export const privateRoutes = {
  pages: [
    {
      name: "Payment Successful",
      link: "/successful-payment",
      content: <SuccessfulPaymentPage />
    },
    {
      name: "Payment Failed",
      link: "/failed-payment",
      content: <FailedPaymentPage />
    }
  ]
};
