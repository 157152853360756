import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  StackDivider,
  Stat,
  StatHelpText,
  StatLabel,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Textarea,
  useDisclosure,
  Wrap,
  WrapItem
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React, { useEffect, useState } from "react";
import { FaFileExcel } from "react-icons/fa";
import { useGetMaskingListQuery, useSendBulkSmsMutation } from "../../../api";
import {
  excelMessageTextParser,
  extractExcelSheetHeaders,
  getLikelyPhoneNumberColumnHeader,
  getSheetsFromWorkbook,
  SheetData
} from "../../../utils/excel-parser.util";
import { convertToValidPhoneNumber } from "../../../utils/phone-number-parser.util";
import { PageCardComponent } from "../../core/components/page-card.component";
import { SendSmsModalComponent } from "./send-sms-modal.component";
import { SmsPreviewComponent } from "./sms-preview.component";

interface ExcelSheetSmsSenderComponentProps {
  uploadedFile: File;
  excelData: SheetData[];
}

export const ExcelSheetSmsSenderComponent: React.FC<
  ExcelSheetSmsSenderComponentProps
> = (props: ExcelSheetSmsSenderComponentProps) => {
  const { data: getMaskingData, isSuccess: getMaskingIsSuccess } =
    useGetMaskingListQuery({});

  const [sendSms, { isSuccess: isSendSmsSuccessful }] =
    useSendBulkSmsMutation();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const [sheetList, setSheetList] = useState<
    {
      label: string;
      value: number;
    }[]
  >();

  const [selectedSheet, setSelectedSheet] = useState<{
    label: string;
    value: number;
  }>();

  const [headerList, setHeaderList] = useState<
    {
      label: string;
      value: string;
    }[]
  >();

  const [phoneNumberHeaderList, setPhoneNumberHeaderList] = useState<
    {
      label: string;
      value: string;
    }[]
  >();

  const [phoneNumberHeader, setPhoneNumberHeader] = useState<{
    label: string;
    value: string;
  }>();

  const [campaignName, setCampaignName] = useState<string>("");
  const [smsContent, setSmsContent] = useState<string>("");
  const [hasUnicode, setHasUnicode] = useState<boolean>(false);
  const [maskingOptions, setMaskingOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const [selectedMask, setSelectedMask] = useState<{
    label: string;
    value: string;
  }>({ label: "No Masking", value: "No Masking" });

  const [convertedPhoneNumberList, setConvertedPhoneNumberList] = useState<
    string[]
  >([]);

  const asciiRegex = /^[\u0000-\u007f]*$/;
  const characterCount =
    selectedSheet && props.excelData[selectedSheet.value].sheetData.length
      ? excelMessageTextParser(
          smsContent,
          props.excelData[selectedSheet.value].sheetData[0]
        ).length
      : 0;
  const smsCount = hasUnicode
    ? Math.ceil(characterCount / 70)
    : Math.ceil(characterCount / 160);

  const charactersPerSms = hasUnicode ? 70 : 160;

  useEffect(() => {
    if (isSendSmsSuccessful) {
      setSmsContent("");
    }
  }, [isSendSmsSuccessful]);

  useEffect(() => {
    if (getMaskingData) {
      const getMaskingDataWithValues = getMaskingData.masking.map((masking) => {
        return {
          label: masking.mask,
          value: masking.mask
        };
      });

      setMaskingOptions([
        { label: "No Masking", value: "No Masking" },
        ...getMaskingDataWithValues
      ]);
    }
  }, [getMaskingIsSuccess, getMaskingData]);

  useEffect(() => {
    const sheetLabels = getSheetsFromWorkbook(props.excelData);

    const sheetLabelsWithValues = sheetLabels.map((sheetLabel, index) => {
      return {
        label: sheetLabel,
        value: index
      };
    });

    setSheetList(sheetLabelsWithValues);
  }, [props.excelData]);

  // On loading sheet list, select the first sheet by default
  useEffect(() => {
    if (sheetList && sheetList.length > 0) {
      setSelectedSheet(sheetList[0]);
    }
  }, [sheetList]);

  // On selecting any sheet, load its headers
  useEffect(() => {
    if (selectedSheet) {
      const sheetHeaderLabels = extractExcelSheetHeaders(
        props.excelData,
        selectedSheet.label
      );

      const sheetHeaderLabelsWithValues = sheetHeaderLabels.map(
        (sheetHeaderLabel) => {
          return {
            label: sheetHeaderLabel,
            value: sheetHeaderLabel
          };
        }
      );

      setHeaderList(sheetHeaderLabelsWithValues);
    }
  }, [selectedSheet, props.excelData]);

  // On sheet change, automatically select a phone number header
  useEffect(() => {
    if (
      selectedSheet &&
      props.excelData[selectedSheet.value].sheetData.length
    ) {
      const likelyPhoneNumberHeaders = getLikelyPhoneNumberColumnHeader(
        props.excelData[selectedSheet.value].sheetData[0]
      );

      const sheetHeaderLabelsWithValues = likelyPhoneNumberHeaders.map(
        (likelyPhoneNumberHeader) => {
          return {
            label: likelyPhoneNumberHeader,
            value: likelyPhoneNumberHeader
          };
        }
      );

      if (sheetHeaderLabelsWithValues.length > 0) {
        setPhoneNumberHeader(sheetHeaderLabelsWithValues[0]);
      }

      setPhoneNumberHeaderList(sheetHeaderLabelsWithValues);
    }
  }, [selectedSheet, props.excelData]);

  // On sheet change, empty the sms body
  useEffect(() => {
    setSmsContent("");
  }, [selectedSheet, props.excelData]);

  // On phone number header switch, convert and cache the phone numbers of that row (performance boost)
  useEffect(() => {
    if (selectedSheet && phoneNumberHeader) {
      const phoneNumberList = props.excelData[
        selectedSheet.value
      ].sheetData.map((sheetRow) => {
        return convertToValidPhoneNumber(
          sheetRow[phoneNumberHeader.value]
        ) as string;
      });

      setConvertedPhoneNumberList(phoneNumberList);
    }
  }, [phoneNumberHeader, props.excelData, selectedSheet]);

  const addSheetColumnToSmsContent = (event: any) => {
    setSmsContent(smsContent + "${excel." + event.target.innerText + "}");
  };

  const changeSmsContent = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSmsContent(event.target.value);

    if (
      selectedSheet &&
      !asciiRegex.test(
        excelMessageTextParser(
          event.target.value,
          props.excelData[selectedSheet.value].sheetData[0]
        )
      ) &&
      event.target.value.length !== 0
    ) {
      setHasUnicode(true);
    } else {
      setHasUnicode(false);
    }
  };

  return (
    <Stack>
      <PageCardComponent>
        <FormControl>
          <FormLabel>Campaign Name</FormLabel>
          <Input
            value={campaignName}
            placeholder="Campaign Description"
            onChange={(event) => {
              setCampaignName(event.target.value);
            }}
          />
        </FormControl>
      </PageCardComponent>
      <Stack
        direction={{ base: "column", xl: "row" }}
        justifyContent="space-between"
        divider={<StackDivider borderColor="gray.200" />}
      >
        <PageCardComponent>
          <Stack>
            <FormLabel>
              Select Sheet from{" "}
              <Tag bgColor={"green.500"} color="white">
                <TagLeftIcon as={FaFileExcel} />
                <TagLabel>{props.uploadedFile.name}</TagLabel>
              </Tag>
            </FormLabel>

            <Select
              selectedOptionStyle="check"
              options={sheetList}
              value={selectedSheet}
              onChange={(event) => {
                if (event) {
                  setSelectedSheet({
                    label: event.label,
                    value: event.value
                  });

                  setHeaderList([]);
                  setPhoneNumberHeaderList([]);
                  setPhoneNumberHeader(undefined);
                  setSmsContent("");
                }
              }}
            />
          </Stack>
        </PageCardComponent>
        <PageCardComponent>
          <Stack>
            <FormLabel>Select Phone Number Column</FormLabel>
            <Select
              selectedOptionStyle="check"
              options={phoneNumberHeaderList}
              value={phoneNumberHeader}
              onChange={(event) => {
                if (event) {
                  setPhoneNumberHeader({
                    label: event.label,
                    value: event.value
                  });
                }
              }}
            />
          </Stack>
        </PageCardComponent>
        <PageCardComponent>
          <Stack>
            <FormLabel>Select Sender Mask</FormLabel>
            <Select
              value={selectedMask}
              onChange={(event) => {
                if (event) {
                  setSelectedMask({
                    label: event.label,
                    value: event.value
                  });
                }
              }}
              selectedOptionStyle="check"
              options={maskingOptions}
            />{" "}
          </Stack>
        </PageCardComponent>{" "}
      </Stack>
      <Stack
        direction={{ base: "column", xl: "row" }}
        justifyContent="space-between"
      >
        <PageCardComponent>
          <Text>Select Sheet Cells</Text>
          <Stack direction="row" flexWrap={"wrap"} spacing="0">
            {headerList?.map((header) => (
              <Box key={header.label} p="1">
                <Button
                  colorScheme="green"
                  variant="outline"
                  onClick={addSheetColumnToSmsContent}
                >
                  {header.label}
                </Button>
              </Box>
            ))}
          </Stack>

          <FormLabel>SMS Content</FormLabel>
          <Textarea
            value={smsContent}
            onChange={changeSmsContent}
            height="100%"
            overflow={"hidden"}
          />
          <Stack
            direction={"row"}
            textAlign="center"
            justify="space-evenly"
            divider={<StackDivider borderColor="gray.200" />}
          >
            <Stat>
              <StatLabel>Characters</StatLabel>
              <StatHelpText>{characterCount}</StatHelpText>
            </Stat>
            <Stat>
              <StatLabel>SMS Count</StatLabel>
              <StatHelpText>{smsCount}</StatHelpText>
            </Stat>
            <Stat>
              <StatLabel>Characters per SMS</StatLabel>
              <StatHelpText>
                {charactersPerSms}
                {hasUnicode ? (
                  <Tag variant="solid" size="sm" mx={2}>
                    Unicode
                  </Tag>
                ) : (
                  <></>
                )}
              </StatHelpText>
            </Stat>
          </Stack>
        </PageCardComponent>
        <PageCardComponent>
          <FormLabel>Sample Preview</FormLabel>
          {phoneNumberHeader && selectedSheet ? (
            <SmsPreviewComponent
              from={selectedMask.value}
              to={
                convertToValidPhoneNumber(
                  props.excelData[selectedSheet.value].sheetData[0][
                    phoneNumberHeader.value
                  ]
                ) as string
              }
              content={excelMessageTextParser(
                smsContent,
                props.excelData[selectedSheet.value].sheetData[0]
              )}
            />
          ) : (
            <Text color="gray.300">No Preview</Text>
          )}
          {selectedSheet && phoneNumberHeader ? (
            <Stack>
              <Button
                onClick={onOpen}
                colorScheme="blue"
                isDisabled={convertedPhoneNumberList.length === 0}
              >
                Send SMS
              </Button>
              <SendSmsModalComponent
                from={selectedMask.value}
                to={convertedPhoneNumberList}
                content={props.excelData[selectedSheet.value].sheetData.map(
                  (sheetRow) => {
                    return excelMessageTextParser(smsContent, sheetRow);
                  }
                )}
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                campaignName={campaignName}
                setSmsContent={setSmsContent}
              />
            </Stack>
          ) : (
            <></>
          )}
        </PageCardComponent>
      </Stack>
    </Stack>
  );
};
