import {
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList
} from "@chakra-ui/react";
import React from "react";

interface RefundPolicyComponentProps {
  modal?: boolean;
}

export const RefundPolicyComponent: React.FC<RefundPolicyComponentProps> = (
  props: RefundPolicyComponentProps
) => {
  return (
    <Stack id="refund-policy" spacing="12" pt={props.modal ? 0 : 12}>
      {!props.modal ? (
        <Heading fontSize={{ base: "2xl", xl: "3xl" }}>Refund Policy</Heading>
      ) : (
        <></>
      )}

      <Text fontSize={{ base: "md", xl: "lg" }}>
        If you are not satisfied with any aspect of our service, please contact
        our support department who will attempt to rectify the situation. If the
        problem cannot be fixed and you are unhappy with the service you are
        entitled to a refund within 30 days. No refunds will be made if we
        terminate your services due to violation of our Acceptable Use Policy or
        any other violation of our Terms and Conditions, except as may be
        provided therein. This does not affect your statutory rights. Credit on
        your account cannot be refunded and must be used for purchases and
        renewals.
      </Text>
      <Stack>
        <Heading fontSize={{ base: "lg", xl: "xl" }}>
          Refunds are not allowed for the following criteria
        </Heading>

        <UnorderedList fontSize={{ base: "md", xl: "lg" }}>
          <ListItem>All types of payment gateway charges. </ListItem>
          <ListItem> Change of mind not applicable in 30 days. </ListItem>
          <ListItem> VAT and tax on the price of goods or services. </ListItem>
          <ListItem>
            {" "}
            Unused credits of the service after validity expired.{" "}
          </ListItem>
          <ListItem>
            {" "}
            Any setup fee or charge like Masking registration charge.{" "}
          </ListItem>
          <ListItem>
            {" "}
            Any payment collection charge like Clearing Cheque, Swift Inward
            Remittance, Intercity online transaction fee etc.{" "}
          </ListItem>
          <ListItem>
            {" "}
            Accounts purchased under a promotion, with or without a promo code,
            are excluded from the 30-day money-back guarantee.{" "}
          </ListItem>
          <ListItem>
            {" "}
            Any funds sent to BinduSMS which are in excess of the amount owed
            can only be reimbursed in credit and cannot be refunded by cash
            money.{" "}
          </ListItem>
        </UnorderedList>
      </Stack>
    </Stack>
  );
};
