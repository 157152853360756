import {
  Button,
  Center,
  Divider,
  Heading,
  Spinner,
  Stack,
  Text
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaCashRegister } from "react-icons/fa";
import { useRechargeBalanceMutation } from "../../../api";
import { CurrentBalanceComponent } from "../../status/components/current-balance.component";
import { MoneyStatComponent } from "../../status/components/money-stat.component";
import { RechargeAmountSetterComponent } from "../components/recharge-amount-setter.component";

interface RechargeAccountPageProps {}

export const RechargeAccountPage: React.FC<RechargeAccountPageProps> = (
  props: RechargeAccountPageProps
) => {
  const [rechargeBalance, rechargeBalanceResult] = useRechargeBalanceMutation(
    {}
  );

  const [rechargeAmount, setRechargeAmount] = useState(50);

  useEffect(() => {
    if (rechargeBalanceResult.isSuccess && rechargeBalanceResult.data) {
      window.location.href = rechargeBalanceResult.data.data.paymentURL;
    }
  }, [rechargeBalanceResult]);

  const handlePayment = () => {
    rechargeBalance({ amount: rechargeAmount });
  };
  return (
    <Stack spacing={4}>
      <Stack direction={{ base: "column", xl: "row" }}>
        <CurrentBalanceComponent />
        <MoneyStatComponent label="Recharge Amount" value={rechargeAmount} />
      </Stack>
      <Divider />
      {!rechargeBalanceResult.isLoading ? (
        rechargeBalanceResult.data ? (
          <Center height="25vh" width="100%">
            <Text>Redirecting to Payment Gateway...</Text>
          </Center>
        ) : (
          <Stack>
            <Stack>
              <Heading fontSize={"2xl"}>Recharge Account By - </Heading>
              <RechargeAmountSetterComponent
                setRechargeAmount={setRechargeAmount}
              />
            </Stack>

            <Divider />
            <Button
              leftIcon={<FaCashRegister />}
              colorScheme="blue"
              onClick={handlePayment}
            >
              Recharge ৳{rechargeAmount}
            </Button>
          </Stack>
        )
      ) : (
        <Center height="25vh" width="100%">
          <Spinner />
        </Center>
      )}
    </Stack>
  );
};
