import { Select } from "chakra-react-select";
import { useEffect, useState } from "react";

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  StackDivider,
  Stat,
  StatHelpText,
  StatLabel,
  Tag,
  Textarea,
  useDisclosure
} from "@chakra-ui/react";

import { PageCardComponent } from "../../core/components/page-card.component";
import {
  PhoneNumberInputComponent,
  PhoneNumberInputOption
} from "../components/phone-number-input.component";
import { SmsPreviewComponent } from "../components/sms-preview.component";
import { useGetMaskingListQuery } from "../../../api";
import { SendSmsModalComponent } from "../components/send-sms-modal.component";

export const SingleSmsTab = () => {
  const { data: getMaskingData, isSuccess: getMaskingIsSuccess } =
    useGetMaskingListQuery({});

  const { isOpen, onClose, onOpen } = useDisclosure();

  const [phoneNumberList, setPhoneNumberList] = useState<
    PhoneNumberInputOption[]
  >([]);

  const asciiRegex = /^[\u0000-\u007f]*$/;
  const [campaignName, setCampaignName] = useState<string>("");
  const [smsContent, setSmsContent] = useState<string>("");
  const [hasUnicode, setHasUnicode] = useState<boolean>(false);
  const [maskingOptions, setMaskingOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const [selectedMask, setSelectedMask] = useState<{
    label: string;
    value: string;
  }>({ label: "No Masking", value: "No Masking" });

  const characterCount = smsContent.length;
  const smsCount = hasUnicode
    ? Math.ceil(characterCount / 70)
    : Math.ceil(characterCount / 160);

  const charactersPerSms = hasUnicode ? 70 : 160;

  useEffect(() => {
    if (getMaskingData) {
      const getMaskingDataWithValues = getMaskingData.masking.map((masking) => {
        return {
          label: masking.mask,
          value: masking.mask
        };
      });

      setMaskingOptions([
        { label: "No Masking", value: "No Masking" },
        ...getMaskingDataWithValues
      ]);
    }
  }, [getMaskingIsSuccess, getMaskingData]);

  const changeSmsContent = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSmsContent(event.target.value);

    if (
      !asciiRegex.test(event.target.value) &&
      event.target.value.length !== 0
    ) {
      setHasUnicode(true);
    } else {
      setHasUnicode(false);
    }
  };

  return (
    <Stack spacing={"2"} divider={<StackDivider borderColor="gray.200" />}>
      <PageCardComponent>
        <FormControl>
          <FormLabel>Campaign Name</FormLabel>
          <Input
            value={campaignName}
            placeholder="Campaign Description"
            onChange={(event) => {
              setCampaignName(event.target.value);
            }}
          />
        </FormControl>
      </PageCardComponent>
      <Stack
        direction={{ base: "column", xl: "row" }}
        justify="space-between"
        divider={<StackDivider borderColor="gray.200" />}
      >
        <PageCardComponent>
          <FormControl>
            <FormLabel>Select Sender Mask</FormLabel>
            <Select
              value={selectedMask}
              onChange={(event) => {
                if (event) {
                  setSelectedMask({
                    label: event.label,
                    value: event.value
                  });
                }
              }}
              selectedOptionStyle="check"
              options={maskingOptions}
            />
          </FormControl>
        </PageCardComponent>
        <PageCardComponent>
          <FormControl>
            <FormLabel>
              Enter Phone Numbers (Total: {phoneNumberList.length})
            </FormLabel>
            <PhoneNumberInputComponent
              phoneNumberList={phoneNumberList}
              setPhoneNumberList={setPhoneNumberList}
            />
          </FormControl>
        </PageCardComponent>
      </Stack>

      <Stack
        direction={{ base: "column", xl: "row" }}
        justify="space-between"
        divider={<StackDivider borderColor="gray.200" />}
      >
        <PageCardComponent>
          <FormLabel>SMS Content</FormLabel>
          <Textarea
            value={smsContent}
            onChange={changeSmsContent}
            height="100%"
            overflow={"hidden"}
          />

          <Stack
            direction={"row"}
            textAlign="center"
            justify="space-evenly"
            divider={<StackDivider borderColor="gray.200" />}
          >
            <Stat>
              <StatLabel>Characters</StatLabel>
              <StatHelpText>{characterCount}</StatHelpText>
            </Stat>
            <Stat>
              <StatLabel>SMS Count</StatLabel>
              <StatHelpText>{smsCount}</StatHelpText>
            </Stat>
            <Stat>
              <StatLabel>Characters per SMS</StatLabel>
              <StatHelpText>
                {charactersPerSms}
                {hasUnicode ? (
                  <Tag variant="solid" size="sm" mx={2}>
                    Unicode
                  </Tag>
                ) : (
                  <></>
                )}
              </StatHelpText>
            </Stat>
          </Stack>
        </PageCardComponent>
        <PageCardComponent>
          <FormLabel>Sample Preview</FormLabel>
          <SmsPreviewComponent
            from={selectedMask.value}
            to={phoneNumberList.length > 0 ? phoneNumberList[0].value : "None"}
            content={smsContent}
          />

          <Stack>
            <Button
              onClick={onOpen}
              colorScheme="blue"
              isDisabled={phoneNumberList.length === 0}
            >
              Send SMS
            </Button>
            <SendSmsModalComponent
              from={selectedMask.value}
              to={phoneNumberList.map((phoneNumber) => {
                return phoneNumber.value;
              })}
              content={phoneNumberList.map(() => {
                return smsContent;
              })}
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              campaignName={campaignName}
              setSmsContent={setSmsContent}
            />
          </Stack>
        </PageCardComponent>
      </Stack>
    </Stack>
  );
};
