import React from "react";
import { Button, SimpleGrid, Stack } from "@chakra-ui/react";
import { rechargeAmountList } from "../data";

interface RechargeAmountSetterComponentProps {
  setRechargeAmount: React.Dispatch<React.SetStateAction<number>>;
}

export const RechargeAmountSetterComponent: React.FC<
  RechargeAmountSetterComponentProps
> = (props: RechargeAmountSetterComponentProps) => {
  return (
    <Stack>
      <Stack width="100%">
        <SimpleGrid columns={[2, 2, 3]} spacing="30px" mt="4">
          {rechargeAmountList.map((recharge) => {
            return (
              <Button
                key={recharge}
                colorScheme="blue"
                variant="outline"
                p={{ base: 6, xl: 12 }}
                fontSize={{ base: "xl", xl: "2xl" }}
                onClick={() => {
                  props.setRechargeAmount(recharge);
                }}
              >
                ৳ {recharge}
              </Button>
            );
          })}
        </SimpleGrid>
      </Stack>
    </Stack>
  );
};
