import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Link,
  Stack,
  Text
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRegisterMutation } from "../../../api";
import { useEffect } from "react";
import { TermsOfServiceModalComponent } from "../../policy/components/terms-of-service-modal.component";
import { PrivacyPolicyModalComponent } from "../../policy/components/privacy-policy-modal.component";
import { RefundPolicyModalComponent } from "../../policy/components/refund-policy-modal.component";
import { BindulogicCreditsComponent } from "../../core/components/bindulogic-credits.component";

type RegisterFormValues = {
  name: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
  hasAccepted: boolean;
};

const registerFormSchema = z
  .object({
    name: z.string().min(3, {
      message: "Name must be at least 3 characters long."
    }),
    phoneNumber: z
      .string()
      .length(11, {
        message: "Please enter a valid 11 digit phone number, e.g. 01XXXXXXXXX"
      })
      .regex(/^01/, {
        message: "Please enter a valid 11 digit phone number, e.g. 01XXXXXXXXX"
      }),
    password: z
      .string()
      .min(8, { message: "Password must be 8 or more characters long" }),
    confirmPassword: z
      .string()
      .min(1, { message: "Please input password again for confirmation" }),
    hasAccepted: z.literal(true, {
      errorMap: () => {
        return {
          message: "Please accept our policies in order to register"
        };
      }
    })
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match.",
    path: ["confirmPassword"]
  });

export const RegisterFormComponent = () => {
  const navigate = useNavigate();
  const [registerUser, registerResult] = useRegisterMutation();

  useEffect(() => {
    if (registerResult.isSuccess) {
      window.localStorage.setItem("jwt", registerResult.data.jwt.jwt);
      window.localStorage.setItem("role", "user");
      navigate("/");
    }
  }, [registerResult]);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<RegisterFormValues>({
    resolver: zodResolver(registerFormSchema)
  });

  const handleRegister = handleSubmit((formData) => {
    registerUser({
      name: formData.name,
      phoneNumber: formData.phoneNumber,
      password: formData.password
    });
  });

  return (
    <Box width="100%">
      <form onSubmit={handleRegister}>
        <Center>
          <Stack spacing={6} w={"full"} maxW={"lg"}>
            <Heading>Register</Heading>

            <FormControl id="name" isInvalid={errors.name ? true : false}>
              <FormLabel>Name</FormLabel>
              <Input
                {...register("name")}
                type="name"
                placeholder="Rakibul Hasan"
              />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              id="phone-number"
              isInvalid={errors.phoneNumber ? true : false}
            >
              <FormLabel>Phone Number</FormLabel>
              <InputGroup>
                <InputLeftAddon children="+88" />
                <Input
                  {...register("phoneNumber")}
                  type="tel"
                  placeholder="01XXXXXXXXX"
                />
              </InputGroup>
              <FormErrorMessage>{errors.phoneNumber?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              id="password"
              isInvalid={errors.password ? true : false}
            >
              <FormLabel>Password</FormLabel>

              <Input
                {...register("password")}
                placeholder="********"
                type="password"
              />

              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              id="password-confirmation"
              isInvalid={errors.confirmPassword ? true : false}
            >
              <FormLabel>Confirm Password</FormLabel>
              <Input
                {...register("confirmPassword")}
                placeholder="********"
                type="password"
              />
              <FormErrorMessage>
                {errors.confirmPassword?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              id="accept-terms"
              isInvalid={errors.hasAccepted ? true : false}
            >
              <Checkbox {...register("hasAccepted")} fontSize="sm">
                <Text fontSize="sm">
                  I accept the <TermsOfServiceModalComponent />,{" "}
                  <PrivacyPolicyModalComponent /> and{" "}
                  <RefundPolicyModalComponent />.
                </Text>
              </Checkbox>
              <FormErrorMessage>{errors.hasAccepted?.message}</FormErrorMessage>
            </FormControl>

            <Stack spacing={6}>
              <Button
                colorScheme={"blue"}
                variant={"solid"}
                type="submit"
                isLoading={registerResult.isLoading}
              >
                Register
              </Button>

              <Divider />
              <Text>
                Already have an account?{" "}
                <Link href="/login" color="blue.500">
                  Login
                </Link>
              </Text>
            </Stack>
          </Stack>
        </Center>
      </form>
      <BindulogicCreditsComponent />
    </Box>
  );
};
