import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure
} from "@chakra-ui/react";
import React from "react";
import { PrivacyPolicyComponent } from "../../policy/components/privacy-policy.component";

interface PrivacyPolicyModalComponentProps {}

export const PrivacyPolicyModalComponent: React.FC<
  PrivacyPolicyModalComponentProps
> = (props: PrivacyPolicyModalComponentProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Button
        as="span"
        variant="link"
        colorScheme="blue"
        onClick={onOpen}
        fontSize={{ base: "sm" }}
      >
        Privacy Policy
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size="xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading fontSize={{ base: "2xl", lg: "3xl" }} py={2}>
              Privacy Policy
            </Heading>
          </ModalHeader>
          <ModalCloseButton my={3} />
          <ModalBody px="8">
            <PrivacyPolicyComponent modal={true} />
          </ModalBody>

          <ModalFooter>
            <Stack direction={"row"}>
              <Button colorScheme="red" variant="outline" onClick={onClose}>
                Close
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
