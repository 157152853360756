import { Button, Stack } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { FaCloudUploadAlt, FaTimes } from "react-icons/fa";
import {
  generateObjectFromExcelFile,
  SheetData
} from "../../../utils/excel-parser.util";

import { FileDropzoneComponent } from "../../core/components/file-dropzone.component";
import { ExcelSheetSmsSenderComponent } from "../components/excel-sheet-sms-sender.component";

export const ExcelSmsTab = () => {
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [excelData, setExcelData] = useState<SheetData[]>();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("File reading was aborted.");
      reader.onerror = () => console.log("File reading has failed.");

      reader.onload = () => {
        const fileArrayBuffer = reader.result as string;
        setExcelData(generateObjectFromExcelFile(fileArrayBuffer));
        setUploadedFile(file);
      };

      reader.readAsArrayBuffer(file);
    });
  }, []);

  return (
    <>
      {!uploadedFile || !excelData ? (
        <FileDropzoneComponent
          allowedFileTypes={{
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              [".xlsx"],
            "text/csv": [".csv"]
          }}
          onDrop={onDrop}
          allowMultiple={false}
          maxFiles={1}
          uploadIcon={<FaCloudUploadAlt size={72} />}
          instructionText="Select/drop Excel (.xlsx) or CSV (.csv) file here"
          dragAcceptText="Release to upload the file"
          dragRejectText="Wrong file type"
        />
      ) : (
        <Stack>
          <Button
            leftIcon={<FaTimes />}
            variant="outline"
            colorScheme={"blackAlpha"}
            onClick={() => {
              setUploadedFile(undefined);
              setExcelData(undefined);
            }}
          >
            Upload Another File
          </Button>
          <ExcelSheetSmsSenderComponent
            uploadedFile={uploadedFile}
            excelData={excelData}
          />
        </Stack>
      )}
    </>
  );
};
