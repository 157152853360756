import { Stack, StackDivider } from "@chakra-ui/react";
import { SmsTransactionTableComponent } from "../components/sms-transaction-table.component";

export const TransactionHistoryTab = () => {
  return (
    <Stack spacing={"2"} divider={<StackDivider borderColor="gray.200" />}>
      <SmsTransactionTableComponent />
    </Stack>
  );
};
