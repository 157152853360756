import { LoginPage } from "../modules/auth/pages/login.page";
import { RegisterPage } from "../modules/auth/pages/register.page";

export const publicRoutes = {
  pages: [
    {
      name: "Login Page",
      link: "/login",
      content: <LoginPage />
    },
    {
      name: "Register",
      link: "/register",
      content: <RegisterPage />
    }
  ]
};
