import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useGetRateListQuery, useSendBulkSmsMutation } from "../../../api";
import { SendBulkSmsRequest } from "../../../api/type";

interface SendSmsModalComponentProps {
  from: string;
  to: string[];
  content: string[];
  isOpen: boolean;
  campaignName: string;
  setSmsContent: React.Dispatch<React.SetStateAction<string>>;
  onOpen: () => void;
  onClose: () => void;
}

export const SendSmsModalComponent: React.FC<SendSmsModalComponentProps> = (
  props: SendSmsModalComponentProps
) => {
  const getRateListQuery = useGetRateListQuery({});
  const [sendSms, sendSmsResult] = useSendBulkSmsMutation();
  const toast = useToast();

  const [smsCountPerSms, setSmsCountPerSms] = useState<number>(0);
  const [totalCost, setTotalCost] = useState<number>(0);
  const [smsList, setSmsList] = useState<SendBulkSmsRequest["smsList"]>([]);

  useEffect(() => {
    if (props.content.length >= 1) {
      const asciiRegex = /^[\u0000-\u007f]*$/;
      const characterCount = props.content[0].length;
      const hasUnicode =
        !asciiRegex.test(props.content[0]) && props.to[0].length !== 0;

      const smsCount = hasUnicode
        ? Math.ceil(characterCount / 70)
        : Math.ceil(characterCount / 160);

      setSmsCountPerSms(smsCount);
    }
  }, [props]);

  useEffect(() => {
    if (props.to.length === props.content.length) {
      if (props.from === "No Masking") {
        let candidateSmsList: { to: string; message: string }[] = [];

        props.content.forEach((smsBody, index) => {
          if (props.to[index]) {
            candidateSmsList.push({
              to: props.to[index],
              message: smsBody
            });
          }
        });

        setSmsList(candidateSmsList);
      } else {
        let candidateSmsList: {
          to: string;
          senderId: string;
          message: string;
        }[] = [];

        props.content.forEach((smsBody, index) => {
          if (props.to[index]) {
            candidateSmsList.push({
              senderId: props.from,
              to: props.to[index],
              message: smsBody
            });
          }
        });
        setSmsList(candidateSmsList);
      }
    }
  }, [props]);

  useEffect(() => {
    if (getRateListQuery.data && getRateListQuery.data.rate.length >= 2) {
      let nonMaskingRate = getRateListQuery.data.rate[0].rate;
      let maskingRate = getRateListQuery.data.rate[1].rate;

      //This was done because sometimes rate[0] may not be the non-masking rate and vice versa
      getRateListQuery.data.rate.map((rateObj: any) => {
        if (rateObj.SMSType === "regular") {
          nonMaskingRate = rateObj.rate;
          return rateObj.rate;
        }
        if (rateObj.SMSType === "masking") {
          maskingRate = rateObj.rate;
          return rateObj.rate;
        }
      });

      if (props.from === "No Masking") {
        setTotalCost(nonMaskingRate * smsList.length * smsCountPerSms);
      } else {
        setTotalCost(maskingRate * smsList.length * smsCountPerSms);
      }
    }
  }, [getRateListQuery.data, props.from, smsList, smsCountPerSms]);

  useEffect(() => {
    if (sendSmsResult.isSuccess) {
      toast({
        title: "SMS Queued",
        description: `${sendSmsResult.data.smsCount} SMS will be sent wthin 60 seconds. Total Cost: ৳ ${sendSmsResult.data.smsCost}`,
        status: "success",
        position: "top",
        duration: 3500,
        isClosable: true
      });

      props.onClose();
      props.setSmsContent("");
    }
  }, [sendSmsResult.isSuccess]);

  return (
    <Stack>
      <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send SMS</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            You are about to send{" "}
            <Text as="span" fontWeight={"bold"}>
              {smsList.length} SMS
            </Text>{" "}
            {props.from === "No Masking"
              ? "without masking"
              : `with masking ${props.from}`}
            . The approximate total cost will be{" "}
            <Text as="span" fontWeight={"bold"}>
              ৳ {totalCost}
            </Text>
            .
          </ModalBody>

          <ModalFooter>
            <Stack direction={"row"}>
              <Button
                colorScheme="red"
                variant="outline"
                onClick={props.onClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => {
                  sendSms({ smsList, campaignName: props.campaignName });
                }}
                isLoading={sendSmsResult.isLoading}
              >
                Send
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};
