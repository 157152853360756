import { CreatableSelect } from "chakra-react-select";
import { KeyboardEventHandler, useEffect, useState } from "react";

import { ActionMeta, OnChangeValue } from "react-select";
import { convertToValidPhoneNumber } from "../../../utils/phone-number-parser.util";

interface Props {
  phoneNumberList: PhoneNumberInputOption[];
  setPhoneNumberList: React.Dispatch<
    React.SetStateAction<PhoneNumberInputOption[]>
  >;
}

export interface PhoneNumberInputOption {
  readonly label: string;
  readonly value: string;
}

export const PhoneNumberInputComponent: React.FC<Props> = (props: Props) => {
  const [inputValue, setInputValue] = useState<string>("");

  const components = {
    DropdownIndicator: null
  };

  const createOption = (label: string) => ({
    label,
    value: label
  });

  const handleChange = (
    newValue: OnChangeValue<PhoneNumberInputOption, true>,
    actionMeta: ActionMeta<PhoneNumberInputOption>
  ) => {
    props.setPhoneNumberList([...newValue]);
  };

  const handleInputChange = (inputValue: string) => {
    let candidatePhoneNumbers: string[] = [];
    let actualPhoneNumbers: string[] = [];
    if (inputValue.length > 14) {
      const commaSeparatedNumbers = inputValue.split(",");
      const spaceSeparatedNumbers = inputValue.split(" ");
      const enterSeparatedNumbers = inputValue.split("\n");

      candidatePhoneNumbers = [
        ...commaSeparatedNumbers,
        ...spaceSeparatedNumbers,
        ...enterSeparatedNumbers
      ];
    } else {
      candidatePhoneNumbers = [inputValue];
    }

    candidatePhoneNumbers.forEach((phoneNumber) => {
      const testNumber = convertToValidPhoneNumber(phoneNumber);
      if (testNumber) {
        actualPhoneNumbers.push(testNumber);
      }
    });

    if (candidatePhoneNumbers.length === 1 && actualPhoneNumbers.length === 0) {
      setInputValue(inputValue);
    } else {
      actualPhoneNumbers.forEach((phoneNumber) => {
        addNumberToList(phoneNumber);
      });
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case " ":
      case "Tab":
        event.preventDefault();
        const convertedPhoneNumber = convertToValidPhoneNumber(inputValue);
        if (convertedPhoneNumber !== null) {
          addNumberToList(convertedPhoneNumber);
        } else {
          setInputValue("");
        }
    }
  };

  const addNumberToList = (inputValue: string) => {
    setInputValue("");
    if (
      props.phoneNumberList.findIndex(
        (listObject) => listObject.label === inputValue
      ) === -1
    ) {
      props.setPhoneNumberList([
        ...props.phoneNumberList,
        createOption(inputValue)
      ]);
    }
  };

  useEffect(() => {}, [props.phoneNumberList]);

  return (
    <CreatableSelect
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder="Type phone numbers..."
      value={props.phoneNumberList}
      colorScheme="blue"
      chakraStyles={{
        multiValue: (provided, state) => ({
          ...provided,
          background: "gray.200",
          px: 2,
          py: 1,
          ml: 0,
          mr: 2
        }),
        inputContainer: (provided, state) => ({
          ...provided,
          m: 0
        })
      }}
    />
  );
};
