import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  usePinInputContext,
  useToast
} from "@chakra-ui/react";
import {
  useGenerateTotpMutation,
  useGetMeQuery,
  useVerifyTotpMutation
} from "../../../api";
import React, { useEffect, useState } from "react";

interface DeactivatedAccountComponentProps {}

export const DeactivatedAccountComponent: React.FC<
  DeactivatedAccountComponentProps
> = (props: DeactivatedAccountComponentProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const getMeResult = useGetMeQuery({});
  const [generateTotp, generateTotpResult] = useGenerateTotpMutation();
  const [verifyTotp, verifyTotpResult] = useVerifyTotpMutation();
  const [activated, setActivated] = useState(true);
  const toast = useToast();

  const [otp, setOtp] = useState<string>("");

  const [blockOtpGeneration, setBlockOtpGeneration] = useState(false);

  useEffect(() => {
    if (generateTotpResult.isSuccess) {
      setBlockOtpGeneration(true);
      setTimeout(() => {
        setBlockOtpGeneration(false);
      }, 60000);
    }
  }, [generateTotpResult.isSuccess]);

  const handleChange = (value: string) => {
    setOtp(value);
  };

  useEffect(() => {
    if (getMeResult.data && !getMeResult.data.user.isActive) {
      setActivated(false);
    } else if (getMeResult.data && getMeResult.data.user.isActive) {
      setActivated(true);
    }
  }, [getMeResult.data]);

  useEffect(() => {
    if (verifyTotpResult.isSuccess) {
      toast({
        title: "Activation",
        description: "Account activated successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
      onClose();
    }
  }, [verifyTotpResult.isSuccess]);

  return (
    <>
      <Alert status="error" display={activated ? "none" : "flex"}>
        <AlertIcon />
        <AlertDescription>
          Your account is not activated. Please verify your phone number to
          continue using our services.{" "}
          <Button variant={"link"} onClick={onOpen} colorScheme="blue">
            Click here to verify phone number
          </Button>
        </AlertDescription>
      </Alert>
      <Stack>
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Verify Phone Number</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing="4">
                {getMeResult.data ? (
                  <Stack spacing="4">
                    <Button
                      colorScheme="blue"
                      variant="outline"
                      onClick={() => {
                        generateTotp({});
                      }}
                      loadingText={"OTP Sent. Resend an OTP in 60 seconds"}
                      isLoading={blockOtpGeneration}
                    >
                      Send OTP to {getMeResult.data.user.phone}
                    </Button>{" "}
                    <>
                      <Center>
                        <Heading fontSize="md">Your OTP</Heading>{" "}
                      </Center>
                      <Center>
                        <HStack>
                          <PinInput
                            otp
                            size="lg"
                            placeholder="-"
                            onChange={handleChange}
                          >
                            <PinInputField borderColor="blue.900" />
                            <PinInputField borderColor="blue.900" />
                            <PinInputField borderColor="blue.900" />
                            <PinInputField borderColor="blue.900" />
                          </PinInput>
                        </HStack>
                      </Center>
                      <Button
                        colorScheme="blue"
                        isLoading={verifyTotpResult.isLoading}
                        onClick={() => {
                          verifyTotp({ totp: otp });
                        }}
                      >
                        Verify OTP
                      </Button>
                    </>
                  </Stack>
                ) : (
                  <Center>
                    <Spinner />
                  </Center>
                )}

                {generateTotpResult.isSuccess && !verifyTotpResult.isSuccess ? (
                  <></>
                ) : (
                  <></>
                )}
              </Stack>
            </ModalBody>

            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
    </>
  );
};
