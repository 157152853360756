import { IDashboard } from "../route.type";
import { SmsPage } from "../../modules/sms/pages/sms.page";
import { StatusPage } from "../../modules/status/pages/status.page";
import { DeveloperPage } from "../../modules/developer/pages/developer.page";
import { BindusmsLogoComponent } from "../../branding/bindusms-logo.component";
import { PolicyPage } from "../../modules/policy/pages/policy.page";
import { RechargeAccountPage } from "../../modules/recharge/pages/recharge-account.page";

export const userDashboard: IDashboard = {
  dashboardName: "user",
  logo: <BindusmsLogoComponent />,
  content: [
    {
      name: "Status",
      link: "/status",
      content: <StatusPage />
    },
    {
      name: "Send SMS",
      link: "/sms/send",
      content: <SmsPage />
    },
    {
      name: "Recharge Account",
      link: "/recharge",
      content: <RechargeAccountPage />
    },
    {
      name: "Developer Mode",
      link: "/developer",
      content: <DeveloperPage />
    },
    {
      name: "Policies",
      link: "/policies",
      content: <PolicyPage />
    }
  ]
};
