import { Stack } from "@chakra-ui/react";
import React from "react";
import { useGetRateListQuery } from "../../../api";
import { CurrentBalanceComponent } from "./current-balance.component";
import { MoneyStatComponent } from "./money-stat.component";

interface BalanceAndBillsComponentProps {}

export const BalanceAndBillsComponent: React.FC<
  BalanceAndBillsComponentProps
> = (props: BalanceAndBillsComponentProps) => {
  const getRateListQuery = useGetRateListQuery({});

  return (
    <Stack>
      <Stack direction={{ base: "column", xl: "row" }}>
        <CurrentBalanceComponent />

        {getRateListQuery.data ? (
          getRateListQuery.data.rate.map((rate) => {
            return (
              <MoneyStatComponent
                key={rate.SMSType}
                label={rate.SMSType}
                value={rate.rate}
              />
            );
          })
        ) : (
          <></>
        )}
      </Stack>
    </Stack>
  );
};
