import { Stack, StackDivider } from "@chakra-ui/react";
import { SmsCampaignTableComponent } from "../components/sms-campaign-table.component";

export const SmsCampaignTab = () => {
  return (
    <Stack spacing={"2"} divider={<StackDivider borderColor="gray.200" />}>
      <SmsCampaignTableComponent />
    </Stack>
  );
};
