// Fonts

import "@fontsource/montserrat";
import "@fontsource/cabin";
import "@fontsource/comfortaa";

const fonts = {
  heading: "Montserrat",
  body: "Cabin"
};

export default fonts;
