import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Link,
  Stack,
  Text
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDocumentTitle } from "../../../hooks/use-document-title.hook";
import { useLoginMutation } from "../../../api";
import { useEffect } from "react";
import { TermsOfServiceModalComponent } from "../../policy/components/terms-of-service-modal.component";
import { PrivacyPolicyModalComponent } from "../../policy/components/privacy-policy-modal.component";
import { RefundPolicyModalComponent } from "../../policy/components/refund-policy-modal.component";
import { BindulogicCreditsComponent } from "../../core/components/bindulogic-credits.component";

type LoginFormValues = {
  phoneNumber: string;
  password: string;
};

const loginFormSchema = z.object({
  phoneNumber: z
    .string()
    .length(11, {
      message: "Please enter a valid 11 digit phone number, e.g. 01XXXXXXXXX"
    })
    .regex(/^01/, {
      message: "Please enter a valid 11 digit phone number, e.g. 01XXXXXXXXX"
    }),
  password: z
    .string()
    .min(8, { message: "Password must be 8 or more characters long" })
});

export const LoginFormComponent = () => {
  const navigate = useNavigate();
  const [login, loginResult] = useLoginMutation();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginFormValues>({
    resolver: zodResolver(loginFormSchema)
  });

  useEffect(() => {
    if (loginResult.isSuccess) {
      window.localStorage.setItem("jwt", loginResult.data.jwt);
      window.localStorage.setItem("role", "user");

      navigate("/");
    }
  }, [loginResult, navigate]);

  const handleLogin = handleSubmit((data) => {
    login({
      phone: data.phoneNumber,
      password: data.password
    });
  });

  useDocumentTitle("Login | BinduSms");

  return (
    <Stack>
      <Box width="100%">
        <form onSubmit={handleLogin}>
          <Center>
            <Stack spacing={4} w={"full"} maxW={"lg"}>
              <Heading fontSize={"2xl"}>Login</Heading>
              <FormControl
                id="phone-number"
                isInvalid={errors.phoneNumber ? true : false}
              >
                <FormLabel>Phone Number</FormLabel>
                <InputGroup>
                  <InputLeftAddon children="+88" />
                  <Input
                    {...register("phoneNumber")}
                    type="tel"
                    placeholder="01XXXXXXXXX"
                  />
                </InputGroup>
                <FormErrorMessage>
                  {errors.phoneNumber?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                id="password"
                isInvalid={errors.password ? true : false}
              >
                <FormLabel>Password</FormLabel>
                <Input
                  {...register("password")}
                  placeholder="********"
                  type="password"
                />{" "}
                <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
              </FormControl>

              {/* <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <Checkbox>Remember me</Checkbox>
                <Link color={"blue.500"}>Forgot password?</Link>
              </Stack> */}

              <FormControl id="accept-terms">
                <Text fontSize="sm">
                  Read our <TermsOfServiceModalComponent />,{" "}
                  <PrivacyPolicyModalComponent /> and{" "}
                  <RefundPolicyModalComponent />.
                </Text>
              </FormControl>

              <Button
                colorScheme={"blue"}
                variant={"solid"}
                type="submit"
                isLoading={loginResult.isLoading}
              >
                Login
              </Button>

              <Divider />
              <Text>
                Don't have an account?{" "}
                <Link href="/register" color="blue.500">
                  Register
                </Link>
              </Text>
            </Stack>
          </Center>
        </form>{" "}
      </Box>{" "}
      <BindulogicCreditsComponent />
    </Stack>
  );
};
