import { Divider, Heading, Link, Stack, StackDivider } from "@chakra-ui/react";
import React from "react";
import { PrivacyPolicyComponent } from "../components/privacy-policy.component";
import { RefundPolicyComponent } from "../components/refund-policy.component";
import { TermsOfServiceComponent } from "../components/terms-of-service.component";

interface PolicyPageProps {}

export const PolicyPage: React.FC<PolicyPageProps> = (
  props: PolicyPageProps
) => {
  return (
    <Stack>
      <Divider />
      <Stack
        direction={{ base: "column-reverse", xl: "row" }}
        divider={<StackDivider />}
        py="8"
        spacing={{ base: 0, xl: 12 }}
      >
        <Stack
          maxWidth="1024px"
          pt={{ base: 12, lg: 0 }}
          spacing={16}
          divider={<StackDivider />}
        >
          <TermsOfServiceComponent />
          <PrivacyPolicyComponent />
          <RefundPolicyComponent />
        </Stack>

        <Stack px={{ base: 0, xl: 6 }}>
          <Stack
            position={{ base: "static", xl: "sticky" }}
            top={{ base: "0", xl: "5vh" }}
            spacing="12"
            pb="12"
          >
            <Heading fontSize={{ base: "2xl", xl: "3xl" }}>Contents</Heading>
            <Stack>
              <Link color="blue.600" href="#terms-of-service">
                Terms of Service
              </Link>
              <Link color="blue.600" href="#privacy-policy">
                Privacy Policy
              </Link>
              <Link color="blue.600" href="#refund-policy">
                Refund Policy
              </Link>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
