import { Box, Heading, Stack, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { FaInfoCircle } from "react-icons/fa";

interface MoneyStatComponentProps {
  label: string;
  value: number;
}

export const MoneyStatComponent: React.FC<MoneyStatComponentProps> = (
  props: MoneyStatComponentProps
) => {
  let label = props.label;

  if (label === "regular") label = "Regular SMS Rate";
  else if (label === "masking") label = "Masking SMS Rate";
  else if (label === "otp") label = "OTP SMS Rate";

  const shouldShowInformation = label === "Current Balance";

  return (
    <Stack p="4" bg="white" borderRadius="lg" boxShadow="sm" width="100%">
      <Stack direction={"row"} justify="space-between">
        <Text fontSize="sm" color="muted">
          {label}
        </Text>
        <Tooltip
          borderRadius={"md"}
          p="4"
          label="To get better rates for your enterprise, please contact us (contact information is at page footer)"
        >
          <Box display={shouldShowInformation ? "flex" : "none"}>
            <FaInfoCircle />
          </Box>
        </Tooltip>
      </Stack>
      <Heading fontSize={{ base: "2xl", lg: "4xl" }}>
        ৳{" "}
        {props.value.toLocaleString("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })}
      </Heading>
    </Stack>
  );
};
