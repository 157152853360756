import React from "react";
import { Spinner } from "@chakra-ui/react";
import { useGetBalanceQuery } from "../../../api";
import { MoneyStatComponent } from "./money-stat.component";

interface CurrentBalanceComponentProps {}

export const CurrentBalanceComponent: React.FC<CurrentBalanceComponentProps> = (
  props: CurrentBalanceComponentProps
) => {
  const getBalanceQuery = useGetBalanceQuery({});

  return (
    <>
      {getBalanceQuery.data ? (
        <MoneyStatComponent
          label="Current Balance"
          value={getBalanceQuery.data.balance}
        />
      ) : (
        <Spinner />
      )}
    </>
  );
};
