import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from "@chakra-ui/react";
import { useGetMeQuery } from "../../../api";
import { PromotionalSmsPolicyModalComponent } from "../../policy/components/promotional-sms-policy-modal.component";
import { ExcelSmsTab } from "../tabs/excel-sms.tab";
import { SingleSmsTab } from "../tabs/single-sms.tab";

export const SmsPage = () => {
  const getMeResult = useGetMeQuery({});

  return (
    <>
      {getMeResult.data && getMeResult.data.user.isActive ? (
        <Tabs variant="enclosed-colored" colorScheme="blue">
          <PromotionalSmsPolicyModalComponent />
          <TabList>
            <Tab>Single SMS</Tab>
            <Tab>Excel SMS</Tab>
          </TabList>
          <TabPanels>
            <TabPanel px={0}>
              <SingleSmsTab />
            </TabPanel>
            <TabPanel px={0}>
              <ExcelSmsTab />
            </TabPanel>
          </TabPanels>
        </Tabs>
      ) : (
        <Text>Please activate your account to avail this feature.</Text>
      )}
    </>
  );
};
