import { Image } from "@chakra-ui/react";

export const DecorationImageComponent = () => {
  return (
    <Image
      alt="BinduSMS Cover Image"
      objectFit="cover"
      src={"images/bindusms-cover-image.jpg"}
    />
  );
};
